import React, { useState } from 'react';
import Layout from "../components/Shared/Layout";
import { ContentWrapper, PathHeader, ContentHeader } from "../components/PageContentWidgets";
import { GoogleApiWrapper } from "google-maps-react";
import styled from "styled-components";
import Number from '../img/phone.jpeg'
import WhatsApp from '../img/whatsapp.jpeg'
import Fax from '../img/fax.jpeg'
import WorkingH from '../img/workingH.jpeg'
import {useTranslation} from "react-i18next";

const FormButton = styled.button`
    display: none;
        @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 24px;
        gap: 12px;
        background: #03153D;
        border-radius: 8px;
        border: none;
        width: 352px;
        color: #fff;
        margin-bottom: 24px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
    }
`

const Info = styled.div`
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 16px;
    
    width: 352px;
    height: 616px;
    
    background: #FFFFFF;
    
    border: 1px solid #E6E8EC;
    border-radius: 16px;
`

const InfoItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    
    width: 271px;
    height: 94px;
    position: relative;
`

const InfoHeader = styled.div`
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px;
`

const InfoSecondary = styled.div`
    width: 100%;
    height: 18px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    
    color: #141416;
    margin-bottom: 16px;
`

const InfoThird = styled.div`
    width: 271px;
    height: 24px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    
    display: flex;
    //align-items: space-around;
    color: #777E91;

`
const Divider = styled.div`
    //padding: 0;
    width: 100%;
    border-bottom: 1px solid #E6E8EC;
    margin-bottom: 16px;
`

const Image = styled.img`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6.4px;
    gap: 8px;
    
    width: 32px;
    height: 32px;
    margin-right: 10px;
`

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`
const ContactsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

const FormWrapper = styled.div`
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px;
    gap: 16px;
    
    width: 670px;
    height: 666px;
    
    background: #FFFFFF;
    
    border: 1px solid #E6E8EC;
    border-radius: 16px;
    @media (max-width: 768px) {
      display: none;
    }
`

const FormHeader = styled.div`
    height: 40px;
    
    
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    
    letter-spacing: -0.01em;
    color: #141416;
`

const FormSubHeader = styled.div`
    width: 124px;
    height: 24px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #353945;
    margin-bottom: 20px;
`
const FormBtn = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 12px;
    
    width: 136px;
    height: 52px;
    background: #0068FF;
    border: none;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FCFCFD;
`

const Form = styled.form`
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
`
const Input = styled.input`
    width: 100%;
    height: 40px;
    border: 1px solid #E6E8EC;
    border-radius: 10px;
    padding: 10px;
    margin: 0;
`
const TextArea = styled.textarea`
    width: 100%;
    height: 120px;
    border: 1px solid #E6E8EC;
    border-radius: 10px;
    padding: 10px;
    margin-right: 0;
`

const Contacts = (props) => {
    const [name, setName] = useState("");
    const [num, setNum] = useState("");
    const { t } = useTranslation();

    const onPhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setNum(formattedPhoneNumber);
    }

    const formatPhoneNumber = (value) => {
        if(!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        return (
            `+${phoneNumber}`
        )
    }
    return (
        <Layout>
            <div>
                <PathHeader paths={[t('Main'), "Контакты"]} />
                <ContentWrapper>
                    <ContentHeader title={"Контакты"} />
                    <ContactsWrapper>
                        <FormButton>Написать письмо</FormButton>
                        <Info>
                            <InfoItems>
                                <InfoHeader>Головной Банк</InfoHeader>
                                <InfoSecondary>г.Бишкек</InfoSecondary>
                                <InfoThird style={{ marginBottom: "16px" }}>720021, КР, г.Бишкек; ул.Шопокова, 101</InfoThird>
                                <Divider />
                            </InfoItems>
                            <InfoItems style={{ marginTop: "16px" }}>
                                <Wrapper>
                                    <Image src={Number} />
                                    <div>
                                        <InfoThird>{t('Main phone')}:</InfoThird>
                                        <InfoSecondary>+996 312 33 30 00</InfoSecondary>
                                    </div>
                                </Wrapper>
                                <Wrapper>
                                    <Image src={WhatsApp} />
                                    <div>
                                        <InfoThird>WhatsApp</InfoThird>
                                        <InfoSecondary>+996 312 33 30 00</InfoSecondary>
                                    </div>
                                </Wrapper>
                                <Wrapper>
                                    <Image src={Fax} />
                                    <div style={{ marginBottom: "16px" }}>
                                        <InfoThird>Факс:</InfoThird>
                                        <InfoSecondary style={{ marginBottom: "0" }}>+996 312 33 30 00</InfoSecondary>
                                        <InfoThird >(доб. 17052 - опер.отдел)</InfoThird>
                                    </div>
                                </Wrapper>
                                <Divider />
                                <Wrapper>
                                    <Image src={WorkingH} />
                                    <div>
                                        <InfoThird>{t('Working hours')}: <span style={{ color: "#111" }}> 09:00 - 18:00</span> </InfoThird>
                                        <InfoThird>({t('break')}: 13:00 - 14:00)</InfoThird>
                                        <br />
                                        <InfoThird>Касса: <span style={{ color: "#111" }}> 9:00 - 17:30 </span></InfoThird>
                                        <InfoThird>(операционный отдел и касса без перерыва)</InfoThird>
                                        <br />
                                        <br />
                                        <InfoThird>{t('Working days')}: <span style={{ color: "#111" }}> Ежедневно</span></InfoThird>
                                    </div>
                                </Wrapper>
                            </InfoItems>
                        </Info>
                        <FormWrapper>
                            <FormHeader>Написать письмо</FormHeader>
                            <FormSubHeader>Обратная связь</FormSubHeader>
                            <Form >
                                <div style={{ marginBottom: "30px" }} class="field">
                                    <label style={{ color: "#B1B5C4" }}>ВАШЕ ИМЯ
                                        <Input required value={name} onChange={(e) => {setName(e.target.value); console.log(name)}}  type="text" placeholder="Имя" />
                                    </label>
                                </div>
                                <div style={{ marginBottom: "30px" }} class="field">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <label style={{ color: "#B1B5C4", width: "47%" }}>НОМЕР ТЕЛЕФОНА
                                            <Input required minLength={13} maxLength={13} value={num} onChange={e => onPhoneChange(e)} type="tel" placeholder="+996" />
                                        </label>
                                        <label style={{ color: "#B1B5C4", width: "47%" }}>EMAIL
                                            <Input required type="email" placeholder='@' />
                                        </label>
                                    </div>
                                </div>
                                <div style={{ marginBottom: "30px" }} class="field">
                                    <label style={{ color: "#B1B5C4" }}>СООБЩЕНИЕ
                                        <TextArea required placeholder='Ваше сообщение'></TextArea>
                                    </label>
                                </div>
                                <div >
                                    <FormBtn  style={{ marginBottom: "30px", cursor: "pointer" }}>Отправить</FormBtn>
                                </div>

                            </Form>
                        </FormWrapper>
                    </ContactsWrapper>
                </ContentWrapper>
            </div>
        </Layout>

    )

}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyALgbbzlV6iXdzL4jc9l0aq0Ml3G84sLYs')
})(Contacts);
