import React, {useEffect} from 'react';
import styled from 'styled-components';
import AOS from "aos";
import "aos/dist/aos.css";
import {Button} from "../../UI/CommonClasses";
import {replaceWithBr} from "../../Shared/sharedFunction";


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 32px;
  @media (max-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 0;
    box-sizing: border-box;
  }
`;
const Content = styled.div`
  width: 1120px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  border-radius: 24px;
  align-items: center;
  background-color: ${(props)=>{
    return props.bg ?? '#F7F9FE';
  }} ;
  color: ${(props)=>{
    return props.colorTxt ?? 'black';
  }};
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column !important;
    padding: 0;
    height: unset;
  }
`;

const Content2 =styled.div`
  width: 1120px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: 512px;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    //padding: 32px 24px 0 24px;
    height: 100%;
  }`
const RightContent = styled.div`
  border-radius: 24px;
  width: 50%;
  padding-left: 64px;
  padding-bottom: 64px;
  background-size: cover !important;
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
  }
`;
const TopTitle = styled.span`
  margin-bottom: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #141416;
  background: #FFD400;
  border-radius: 4px;
  padding: 6px 12px;
  width: max-content;
  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;
const Subtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
const Title = styled.div`
  font-family: Nexa;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.01em;
  padding-bottom: 16px;
  padding-top: 64px;
  @media (max-width: 768px) {
    font-size: 24px;
    width: unset;
    line-height: 120%;
    padding-top: 0;
  }
`;
const Title2 = styled.div`
  font-family: Nexa;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.01em;
  padding-bottom: 16px;
  padding-top: 64px;
  padding-left: 64px;
  @media (max-width: 768px) {
    font-size: 24px;
    width: unset;
    line-height: 120%;
    padding-top: 0;
    padding-left: 0;
  }
`;
const  LeftContent = styled.div`
  height: 100%;
  background-size: cover !important;
  border-radius: 24px;
  width: 50%;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 24px;
    width: 100%;
  }
`;
const LeftContent2 = styled.div`
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: black;
  min-height: 516px;
  border-radius: 24px;
  margin-right: 32px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const RightContent2 = styled.div`
  border-radius: 24px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: black;
  min-height: 516px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
  @media (max-width: 768px) {
    width: unset;
    display: flex;
    flex-direction: column-reverse;
    min-height: 382px;
    background: #F7F9FE;
    border-radius: 16px;
    justify-content: unset;
  }

`;
const Img = styled.img`
  object-fit: contain;
  width:100%;
  height: 100%;
  border-radius: 24px 0 0 24px;
  @media (max-width: 768px) {
    border-radius: 24px 24px 0 0 !important;
  }
`;
const ButtonCustom = styled.button`
  cursor: pointer;
  margin: 0 64px;
  padding: 16px 24px;
  background: #0068FF;
  border-radius: 8px;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  color: #FCFCFD;
`;

const Button2 = styled.button`
  cursor: pointer;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #FCFCFD;
  background: #0068FF;
  border: 2px solid #E6E8EC;
  box-sizing: border-box;
  border-radius: 8px;
  width: 190px;
  text-align: center;
  margin-bottom: 64px;
  margin-left: 64px;
  padding: 16px 24px;
  @media (max-width: 768px) {  
    display: none;
  }
`;

const MobileBtn = styled.div`
display: none;
@media (max-width: 768px) {
  display: block;
}
`

const UpperContent = styled.div`
    display: none;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 32px 32px 32px 32px;
    padding: 32px 24px 32px 24px;
    height: unset;
    background: #F7F9FE;
    border-radius: 16px;
  }
`
const ImgZone = styled.div`
    display: none;
  
  @media (max-width: 768px) {
    display: block;
    color: black;
    flex-grow: 1;
    flex-basis: 0;
    width:100%;
    height: 100%;
  }
`
const InfoZonet = styled.div`
    display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const TitleMobile = styled.div`
    display: none;
  
  @media (max-width: 768px) {
    display: block;
    font-family: Nexa;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 48px;

    letter-spacing: -0.01em;
    color: #03153D;
    padding-bottom: 16px;
  }
`

const SubtitleMobile = styled.div`
    display: none;
  
  @media (max-width: 768px) {
    display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #353945;
    padding-bottom: 40px;
  }
`
const Items = styled.div`
  display:flex;
  padding-top: 32px;
  padding-bottom: 40px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
const ItemsFace = styled.div`
  display:flex;
  padding-top: 32px;
  padding-bottom: 40px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    display: flex;
  }
`

const Item =styled.div`
  padding-right: 64px;
  @media (max-width: 768px) {
    padding-right: 0;
    padding-bottom: 20px;
  }

`
const ItemsTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #141416;
  padding-bottom: 8px;
`
const ItemsSubTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #777E91;
  white-space: nowrap;
`

const Link=styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #03153D;
  padding-left: 40px;
  cursor: pointer;
   display: flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 16px 0;
    margin-top: 16px;
  }
`

const ButtonGroup=styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
  }
`


const CardBlock = ({parameters, left ,right})=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    if(parameters?.type==="1"||parameters?.type==="2"||parameters?.type==='карточка(правый)'||parameters?.type==='карточка(левый)'){
        return(
            <Wrapper data-aos='fade-up' data-aos-duration="500">
                <Content bg={parameters.bg_color}  colorTxt={parameters?.text_color} style={{flexDirection:parameters["type"]==='2'||parameters?.type==='карточка(правый)'?'row':'row-reverse'}}>
                    <LeftContent style={{marginRight:'0'}}>
                        <Img src={parameters.image} style={{borderRadius: parameters["type"]==='2'||parameters?.type==='карточка(левый)'?'24px 0px 0px 24px':'0px 24px 24px 0px'}}/>
                    </LeftContent>
                    <RightContent>
                        {parameters.tag?<TopTitle>{parameters.tag}</TopTitle>:null}
                        <Title>{parameters.title}</Title>
                        <Subtitle  dangerouslySetInnerHTML={{__html: replaceWithBr(parameters.description)}}/>
                        {parameters.items.length?
                            <Items style={{}}>
                                {parameters.items.map(e =>
                                    (<div style={{paddingRight:'16px'}}>
                                        <ItemsTitle>{e.title}</ItemsTitle>
                                        <ItemsSubTitle  dangerouslySetInnerHTML={{__html: replaceWithBr(e.description)}}/></div>))}
                            </Items>
                            :null}
                        <ButtonGroup>
                            <Button
                                onClick={()=> {
                                    if(parameters.button1_link) return window.open(parameters.button1_link, "_self");
                                }} Modal={!!parameters.button1_modal} ModalText={parameters.button1_text}>{parameters.button1_text}</Button>
                            {parameters.button2_link?<Link onClick={()=>window.open(parameters.button2_link, "_self")}>{parameters.button2_text}</Link>:null}
                        </ButtonGroup>
                    </RightContent>
                </Content>
            </Wrapper>
        )
    }

    else if(parameters?.type==="5"
        ||parameters?.type==="6"
        ||parameters?.type==="инфо блок(правый)"
        ||parameters?.type==="инфо блок(левый)"
    ){
        return(
            <Wrapper data-aos='fade-up' data-aos-duration="500">
                <Content bg={'white'}  colorTxt={parameters?.text_color} style={{flexDirection:parameters?.type==="5"||parameters?.type==="инфо блок(правый)"?'row':'row-reverse'}}>
                    <LeftContent >
                        <Img src={parameters.image} style={{borderRadius:"24px"}}/>
                    </LeftContent>
                    <RightContent>
                        {parameters.tag?<TopTitle>{parameters.tag}</TopTitle>:null}
                        <Title>{parameters.title}</Title>
                        <Subtitle  dangerouslySetInnerHTML={{__html: replaceWithBr(parameters.description)}}/>
                        {parameters.items.length?
                            <Items style={{}}>
                                {parameters.items.map(e =>
                                    (<div style={{paddingRight:'64px'}}>
                                        <ItemsTitle>{e.title}</ItemsTitle>
                                        <ItemsSubTitle  dangerouslySetInnerHTML={{__html: replaceWithBr(e.description)}}/></div>))}
                            </Items>
                            :null}
                        <ButtonGroup>
                            {parameters.button1_link? <Button onClick={()=>window.open(parameters.button1_link, "_self")} Modal={!!parameters.button1_modal}  ModalText={parameters.button1_text}>{parameters.button1_text}</Button> :null}
                            {parameters.button2_link?<Link onClick={()=>window.open(parameters.button2_link, "_self")}>{parameters.button2_text}</Link>:null}
                        </ButtonGroup>
                    </RightContent>
                </Content>
            </Wrapper>
        )
    }
    else if(
        parameters?.type==='7'||
        parameters?.type==='лицевой блок'
    ){
        return(
            <Wrapper data-aos='fade-up' data-aos-duration="500" style={{backgroundColor:'#F7F9FE'}} >
                <Content  colorTxt={parameters?.text_color} style={{flexDirection:parameters["type"]===1?'row':'row-reverse'}}>
                    <LeftContent >
                        <Img src={parameters.image} style={{borderRadius:"0px"}}/>
                    </LeftContent>
                    <RightContent>
                        {parameters.tag?<TopTitle>{parameters.tag}</TopTitle>:null}
                        <Title>{parameters.title}</Title>
                        <Subtitle  dangerouslySetInnerHTML={{__html: replaceWithBr(parameters.description)}}/>
                        {parameters.items.length?
                    <ItemsFace style={{}}>
                        {parameters.items.map(e =>
                            (<Item style={{paddingRight:'64px'}}>
                                <ItemsTitle>{e.title}</ItemsTitle>
                                <ItemsSubTitle  dangerouslySetInnerHTML={{__html: replaceWithBr(e.description)}}/></Item>))}
                       </ItemsFace>
                            :null}
                        <ButtonGroup>
                            <Button
                                onClick={()=> {
                                    if(parameters.button1_link) return window.open(parameters.button1_link, "_self")
                                }} Modal={!!parameters.button1_modal}  ModalText={parameters.button1_text}>{parameters.button1_text}</Button>
                            {parameters.button2_link?<Link onClick={()=>window.open(parameters.button2_link, "_self")}>{parameters.button2_text}</Link>:null}
                        </ButtonGroup>
                    </RightContent>
                </Content>
            </Wrapper>
        )
    }else if(left && right){
        return(
            <Wrapper  data-aos='fade-up' data-aos-duration="500">
                <Content2>
                    <LeftContent2 style={{background:`url(${left?.image})`}}>
                        <Title style={{paddingLeft:'64px'}}>
                            {left?.title}
                        </Title>
                        <Subtitle style={{paddingLeft:'64px'}}  dangerouslySetInnerHTML={{__html: replaceWithBr(left?.description)}}/>
                        <ButtonCustom
                            onClick={()=> {
                                if(left.button_link) return window.open(left.button_link, "_self")
                            }}>
                            {left?.button1_text}
                        </ButtonCustom>
                    </LeftContent2>
                    <UpperContent>
                        <ImgZone>
                            <Img src={left?.image}/>
                        </ImgZone>
                        <InfoZonet>
                            <TitleMobile>{left?.title}</TitleMobile>
                            <SubtitleMobile  dangerouslySetInnerHTML={{__html: replaceWithBr(left?.description)}}/>
                            <Button  onClick={()=> {if(left.button_link) return window.open(left?.button_link, "_self")
                            }} Modal={!!left.button1_modal}  ModalText={left.button1_text}>{left?.button1_text}</Button>
                        </InfoZonet>
                    </UpperContent>
                    <RightContent2 style={{background:`url(${right?.image})`}}>
                        <MobileBtn>
                            <Button  onClick={()=> {if (right.button_link) return window.open(right?.button_link, "_self")}} Modal={!!right.button1_modal}  ModalText={right.button1_text}> {right?.button1_text}</Button>
                        </MobileBtn>
                        <Title2>
                            {right?.title}
                        </Title2>
                        <Button2 onClick={()=> {
                            if(right.button_link) return window.open(right?.button_link, "_self")
                        }}>
                            {right?.button1_text}
                        </Button2>
                    </RightContent2>
                </Content2>
            </Wrapper>
        )
    }

return <div>12</div>;
}

export default CardBlock
