import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { Button } from "../UI/CommonClasses";
import { ProductSkeleton } from "../Cover/Cover";
import useLoading from "../../hooks/useLoading";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  height: 100%;
`;

const Content = styled.div`
  width: 1120px;
  justify-content: space-between;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: black;
  min-height: 516px;
  border-radius: 24px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Subtitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #e6e8ec;
  padding: 64px;
  padding-top: 0;
  padding-bottom: 40px;
`;
const ButtonCustom = styled.button`
  cursor: pointer;
  margin: 0 64px;
  padding: 16px 24px;
  background: #0068ff;
  border-radius: 8px;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  color: #fcfcfd;
  cursor: pointer;
`;

const Button2 = styled.button`
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;

  text-align: right;

  color: #fcfcfd;
  background: #0068ff;
  border: 2px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  width: 190px;
  text-align: center;
  margin-bottom: 64px;
  margin-left: 64px;
  padding: 16px 24px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileBtn = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Title = styled.div`
  font-family: "Nexa";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #fcfcfd;
  padding: 64px;
  padding-bottom: 12px;

  @media (max-width: 768px) {
    font-size: 24px;
    padding: unset;
    padding-bottom: 40px;
  }
`;

const RightContent = styled.div`
  border-radius: 24px;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  color: black;
  min-height: 516px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 768px) {
    width: unset;
    display: flex;
    flex-direction: column-reverse;
    margin: 32px 32px 32px 32px;
    padding: 32px 24px 32px 24px;
    height: 460px;
    background: #f7f9fe;
    border-radius: 16px;
    justify-content: unset;
  }
`;

const UpperContent = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 32px 32px 32px 32px;
    padding: 32px 24px 32px 24px;
    height: unset;
    background: #f7f9fe;
    border-radius: 16px;
  }
`;
const ImgZone = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    color: black;
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    height: 100%;
  }
`;

const Img = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 212px;
  }
`;

const InfoZonet = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const TitleMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-family: Nexa;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 48px;

    letter-spacing: -0.01em;
    color: #03153d;
    padding-bottom: 16px;
  }
`;

const SubtitleMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #353945;
    padding-bottom: 40px;
  }
`;

const ContentSkeletLoader = () => {
  return (
    <Content>
      <LeftContent>
        <Title>
          {" "}
          <ProductSkeleton height="30px" />
        </Title>
        <Subtitle>
          {" "}
          <ProductSkeleton />
        </Subtitle>
        <ProductSkeleton height="50px" />
      </LeftContent>

      <UpperContent>
        <ImgZone>
          <ProductSkeleton height="100px" />
        </ImgZone>
        <InfoZonet>
          <TitleMobile>
            <ProductSkeleton height="30px" />
          </TitleMobile>
          <SubtitleMobile>
            <ProductSkeleton />
          </SubtitleMobile>
          <ProductSkeleton height="50px" />
        </InfoZonet>
      </UpperContent>
      <RightContent>
        <MobileBtn>
          <ProductSkeleton height="50px" />
        </MobileBtn>
        <Title>
          <ProductSkeleton height="30px" />
        </Title>
        <ProductSkeleton height="50px" />
      </RightContent>
    </Content>
  );
};

const Banners = ({ banners }) => {
  const { isLoading } = useLoading();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Wrapper data-aos="fade-up" data-aos-duration="500">
      {isLoading && banners ? (
        <Content>
          <LeftContent style={{ background: `url(${banners[0]?.image})` }}>
            <Title>{banners[0]?.title}</Title>
            <Subtitle>{banners[0]?.description}</Subtitle>
            <ButtonCustom
              onClick={() => {
                if (banners[0]?.button_link) {
                  return window.open(banners[0]?.button_link, "_self");
                }
              }}
            >
              {banners[0]?.button_title}
            </ButtonCustom>
          </LeftContent>

          <UpperContent>
            <ImgZone>
              <Img src={banners[0]?.image} />
            </ImgZone>
            <InfoZonet>
              <TitleMobile>{banners[0]?.title}</TitleMobile>
              <SubtitleMobile>{banners[0]?.description}</SubtitleMobile>
              <Button
                onClick={() => {
                  if (banners[0].button_link)
                    return window.open(banners[0].button_link, "_self");
                }}
              >
                {banners[0]?.button_title}
              </Button>
            </InfoZonet>
          </UpperContent>
          <RightContent style={{ background: `url(${banners[1]?.image})` }}>
            <MobileBtn>
              <Button
                onClick={() => {
                  if (banners[1].button_link)
                    return window.open(banners[1].button_link, "_self");
                }}
              >
                {" "}
                {banners[1]?.button_title}
              </Button>
            </MobileBtn>
            <Title>{banners[1]?.title}</Title>
            <Button2
              onClick={() => {
                if (banners[1].button_link)
                  return window.open(banners[1].button_link, "_self");
              }}
            >
              {banners[1]?.button_title}
            </Button2>
          </RightContent>
        </Content>
      ) : (
        <ContentSkeletLoader />
      )}
    </Wrapper>
  );
};

export default Banners;
