import React from "react";
import styled from "styled-components";


const Wrapper = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 128px;
  @media (max-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;


export const ContentWrapper = (props) => {
    return <Wrapper style={props.style}>
        {props.children}
    </Wrapper>
}
