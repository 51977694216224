import React, {useEffect, useState} from "react";
import {ContentWrapper, ContentHeader, PathHeader} from "../components/PageContentWidgets";
import Layout from "../components/Shared/Layout";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import {dataService} from "../services/getContent.service";
import {sendDataService} from "../services/sendData.service";
import {Rating} from "@mui/material";
import FeedbackSentWindow from "../components/Module/FeedbackSentWindow";
import useModule from "../hooks/useModule";

const FormWrapper = styled.div`
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px;
    gap: 16px;
    
    width: 670px;
    
    background: #FFFFFF;
    
    border: 1px solid #E6E8EC;
    border-radius: 16px;
    margin-top: 64px;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 20px;
      padding: 20px;
    }
`

const FormHeader = styled.div`
    height: 40px;
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    
    letter-spacing: -0.01em;
    color: #141416;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const FormSubHeader = styled.div`
    font-family: 'Nexa';
    font-style: normal;
    font-weight: 900;
    font-size: 26px;
    line-height: 30px;
    
    letter-spacing: -0.01em;
    color: #141416;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 12px;
  }
`

const FormBtn = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 12px;
    
    width: 136px;
    height: 52px;
    background: #0068FF;
    border: none;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #FCFCFD;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Form = styled.form`
    width: 100%;
    font-family: 'Inter';
    font-style: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
`
const Input = styled.input`
    width: 100%;
    height: 40px;
    border: 1px solid #E6E8EC;
    border-radius: 10px;
    padding: 10px;
    margin: 0;
  @media (max-width: 768px) {
    width: unset;
  }
`
const TextArea = styled.textarea`
    width: 100%;
    height: 120px;
    border: 1px solid #E6E8EC;
    border-radius: 10px;
    padding: 10px;
    margin-right: 0;
    resize: vertical;
  @media (max-width: 768px) {
    width: unset;
  }
`
const Select = styled.select`
  width: 100%;
  height: 40px;
  border: 1px solid #E6E8EC;
  border-radius: 10px;
  padding: 10px;
  margin: 0;
  box-sizing: content-box;
  @media (max-width: 768px) {
    width: unset;
  }
`
const Option = styled.option`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  margin-right: 12px;
  border-radius: 100px;
  font-size: 14px;
  cursor: pointer;
  &.active{
    background: #0029D3;
    color: white;
  }
`
const CalendarWrapper = styled.div`
    position: absolute;
  z-index: 10000;
`

const Rate = (props) => {
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [point, setPoint] = useState(0);
    const [date, setDate] = useState(new Date());
    const [openCalendar, setOpenCalendar]=useState(false)
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedSubdivision, setSelectedSubdivision] = useState(null);
    const [branches, setBranches] = useState([]);
    const [subdivisions, setSubdivisions] = useState([]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const {isModuleOpen, setModule} = useModule();

    const { t } = useTranslation();
    const onPhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setPhone(formattedPhoneNumber);
    }

    const formatPhoneNumber = (value) => {
        if(!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        return (
            `+${phoneNumber}`
        )
    }

    const sendFeedback = (e) => {
        e.preventDefault();
        if (selectedBranch && selectedSubdivision && point > 0 && phone?.length && name?.length && message.length && date) {
            const feedback = {
                office_id: selectedBranch,
                subdivision_id: selectedSubdivision,
                point: point,
                message: message,
                full_name: name,
                attended_at: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                phone: phone,
            };
            sendDataService.sendFeedback(feedback).then(data => {
                if (data.success) {
                    setSuccess(true);
                    setError(false);
                    setPhone('');
                    setName('');
                    setMessage('');
                    setPoint(0);
                    setDate(new Date());
                    setModule({open: true});
                    setSelectedBranch(branches?.[0]?.id);
                    setSelectedSubdivision(subdivisions?.[0]?.id);
                } else {
                    setError(true);
                }
            }).catch(error => {
                setSuccess(false);
                setError(true);
                setModule({open: true});
            });
        } else {
            alert('Все поля обязательны к заполнению.');
        }
    }

    useEffect(() => {
        dataService.getBranches().then((data) => {
            setBranches(data.data);
            setSelectedBranch(data?.data?.[0]?.id);
        });
        dataService.getSubdivisions().then((data) => {
            setSubdivisions(data.data);
            setSelectedSubdivision(data?.data?.[0]?.id);
        });
    }, []);

    useEffect(()=>{
        setOpenCalendar(false);
    },[date]);

    return (
        <Layout>
            <div>
                <PathHeader paths={[t('Main'), "Отзыв"]} />
                <ContentWrapper>
                    <ContentHeader title={"Отзыв"} />
                    <FormWrapper>
                        <FormSubHeader>
                            Спасибо, что делитесь обратной связью!<br/>
                        </FormSubHeader>
                        <Form onSubmit={sendFeedback}>
                            {branches?.length > 0 && <div style={{ marginBottom: "30px", display: "flex", flexDirection: "column" }}>
                                <label style={{ color: "#B1B5C4", display: "flex", flexDirection: "column" }}>КАКОЕ ОТДЕЛЕНИЕ ВЫ ПОСЕТИЛИ?
                                    <Select onChange={(e) => {
                                        setSelectedBranch(e.target.value);
                                    }}>
                                        {branches.map(item=>{
                                            return  <Option key={item.id}
                                                            className={selectedBranch===item.id?'active':''}
                                                            value={item.id}>
                                                {item.title}
                                            </Option>
                                        })}
                                    </Select>
                                </label>
                            </div>}
                            {subdivisions?.length > 0 && <div style={{ marginBottom: "30px", display: "flex", flexDirection: "column" }}>
                                <label style={{ color: "#B1B5C4", display: "flex", flexDirection: "column" }}>КАКОЕ ПОДРАЗДЕЛЕНИЕ ВЫ ПОСЕТИЛИ?
                                    <Select onChange={(e) => {
                                        setSelectedSubdivision(e.target.value);
                                    }}>
                                        {subdivisions.map(item=>{
                                            return  <Option key={item.id}
                                                            className={selectedSubdivision===item.id?'active':''}
                                                            value={item.id}>
                                                {item.title}
                                            </Option>
                                        })}
                                    </Select>
                                </label>
                            </div>}
                            <div style={{ marginBottom: "30px", display: "flex", flexDirection: "column" }}>
                                <div style={{ color: "#B1B5C4", width: "100%", textTransform: "uppercase" }}>Оцените, пожалуйста, качество обслуживания</div>
                                <Rating
                                    name="user-rating"
                                    value={point}
                                    onChange={(event, newValue) => {
                                        setPoint(newValue);
                                    }}
                                    max={10}
                                    size={"large"}
                                />
                            </div>
                            <div style={{ marginBottom: "30px", display: "flex", flexDirection: "column" }} className="field">
                                <label style={{ color: "#B1B5C4", display: "flex", flexDirection: "column" }}>ВАШЕ ФИО
                                    <Input value={name} onChange={(e) => {setName(e.target.value);}}  type="text" placeholder="ФИО" />
                                </label>
                            </div>
                            <div style={{ marginBottom: "30px", display: "flex", flexDirection: "column" }} className="field">
                                <label style={{ color: "#B1B5C4", display: "flex", flexDirection: "column" }}>ЧТО ВАМ ПОНРАВИЛОСЬ ИЛИ НЕ ПОНРАВИЛОСЬ?
                                    <TextArea
                                        value={message}
                                        onChange={(e) => {setMessage(e.target.value);}}
                                        placeholder='Ваше сообщение'>
                                    </TextArea>
                                </label>
                            </div>
                            <div style={{ marginBottom: "30px", display: "flex", flexDirection: "column" }} className="field">
                                <label style={{ color: "#B1B5C4", display: "flex", flexDirection: "column" }}>ДАТА ВАШЕГО ПОСЕЩЕНИЯ
                                    <Input onClick={()=>setOpenCalendar(true)}
                                           value={date.toLocaleDateString('en-GB')} readOnly/>
                                </label>
                                {openCalendar?     <CalendarWrapper>
                                    <Calendar maxDate={new Date()} onChange={setDate} value={date} locale={'ru'}/>
                                </CalendarWrapper>:null}
                            </div>

                            <div style={{marginBottom: "30px", display: "flex", flexDirection: "column"}} className="field">
                                <div>
                                    <label style={{color: "#B1B5C4", display: "flex", flexDirection: "column"}}>НОМЕР ДЛЯ ОБРАТНОЙ СВЯЗИ
                                        <Input value={phone} onChange={e => onPhoneChange(e)} type="tel" placeholder="+996"/>
                                    </label>
                                </div>
                            </div>
                            <div >
                                <FormBtn style={{ cursor: "pointer" }}>Отправить</FormBtn>
                            </div>
                        </Form>
                    </FormWrapper>
                </ContentWrapper>
                {
                    isModuleOpen.open && <FeedbackSentWindow success={success} error={error}/>
                }
            </div>
        </Layout>
    );
}

export default Rate;
