import React, { useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { ProductSkeleton } from "../Cover/Cover";
import useLoading from "../../hooks/useLoading";
import useWindowSize from "../../hooks/useWindowSize";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  padding-top: 128px;

  @media (max-width: 768px) {
    padding-top: unset;
  }
`;

const Content = styled.div`
  width: 1120px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 32px;
    margin-bottom: unset;
  }
`;

const Title = styled.div`
  font-family: Nexa;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #03153d;
  padding-bottom: 64px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
    padding: 32px;
  }
`;

const Cards = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Card = styled.div`
  cursor: pointer;
  width: 352px !important;
  height: 364px;
  background: #ffffff;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
  margin: 16px;
  margin-bottom: 128px;
  transition: all 0.5s ease-in-out;
  position: relative;
  .arrow {
    display: none;
    position: absolute;
    bottom: 32px;
    right: 32px;
  }
  &:hover {
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    .arrow {
      display: block;
    }
  }
`;

const CardTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #03153d;
  padding-bottom: 16px;
  padding-top: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const CardSubtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #777e91;
  padding-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
`;

const Img = styled.img`
  width: 64px;
  padding-top: 32px;
`;

const CardsMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const ArrowNavigatorLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: url(data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%229%22%20viewBox%3D%220%200%2014%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M4.90906%200.265207C4.50324%20-0.109399%203.87058%20-0.0840962%203.49597%200.321722L0.265201%203.82168C-0.0883963%204.20474%20-0.0884%204.79518%200.265194%205.17824L3.49597%208.67828C3.87057%209.08411%204.50323%209.10941%204.90905%208.73481C5.31487%208.36021%205.34018%207.72755%204.96558%207.32173L3.28397%205.49997L13%205.49997C13.5523%205.49997%2014%205.05225%2014%204.49997C14%203.94768%2013.5523%203.49997%2013%203.49997L3.284%203.49997L4.96557%201.67829C5.34018%201.27247%205.31487%200.639813%204.90906%200.265207Z%22%20fill%3D%22%23777E91%22%2F%3E%0A%3C%2Fsvg%3E%0A);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const ArrowNavigatorRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: url(data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%229%22%20viewBox%3D%220%200%2014%209%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M9.09094%200.265207C9.49676%20-0.109399%2010.1294%20-0.0840962%2010.504%200.321722L13.7348%203.82168C14.0884%204.20474%2014.0884%204.79518%2013.7348%205.17824L10.504%208.67828C10.1294%209.08411%209.49677%209.10941%209.09095%208.73481C8.68513%208.36021%208.65982%207.72755%209.03442%207.32173L10.716%205.49997L0.999999%205.49997C0.447714%205.49997%20-7.64154e-07%205.05225%20-7.86799e-07%204.49997C-8.09444e-07%203.94768%200.447714%203.49997%200.999999%203.49997L10.716%203.49997L9.03443%201.67829C8.65982%201.27247%208.68513%200.639813%209.09094%200.265207Z%22%20fill%3D%22%2303153D%22%2F%3E%0A%3C%2Fsvg%3E%0A);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowNavigatorRight
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "-92px",
        right: "10px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowNavigatorLeft
      className={className}
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "-92px",
        left: "1045px",
      }}
      onClick={onClick}
    />
  );
}

const ContentSkeletLoader = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {props.value.map((e, i) => {
        return (
          <Card key={i + Math.random()}>
            <ImgWrapper>
              <ProductSkeleton height="100px" />
            </ImgWrapper>
            <CardTitle>
              <ProductSkeleton height="30px" />
            </CardTitle>
            <CardSubtitle>
              <ProductSkeleton />
            </CardSubtitle>
          </Card>
        );
      })}
    </div>
  );
};

const SpecialOffers = (props) => {
  const { isLoading } = useLoading();
  const { isDesktop } = useWindowSize();
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settingsMobile = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Wrapper data-aos="fade-up" data-aos-duration="500">
      <Content>
        <Title>{t('Special offers for business')}</Title>
        {isDesktop && (
          <Cards>
            {isLoading && props?.products ? (
              <Slider {...settings}>
                {props?.products?.map((e, i) => {
                  return (
                    <Card
                      key={i + Math.random()}
                      onClick={() => window.open(e.link, "_self")}
                    >
                      <ImgWrapper>
                        <Img src={e.image} />
                      </ImgWrapper>
                      <CardTitle>{e.title}</CardTitle>
                      <CardSubtitle>{e.intro}</CardSubtitle>
                    </Card>
                  );
                })}
              </Slider>
            ) : (
              <ContentSkeletLoader value={["", "", ""]} />
            )}
          </Cards>
        )}

        <CardsMobile>
          {isLoading && props?.products ? (
            <Slider {...settingsMobile}>
              {props?.products?.map((e, i) => {
                return (
                  <Card
                    key={i + Math.random()}
                    onClick={() => window.open(e.link, "_self")}
                  >
                    <ImgWrapper>
                      <Img src={e.image} />
                    </ImgWrapper>
                    <CardTitle>{e.title}</CardTitle>
                    <CardSubtitle>{e.intro}</CardSubtitle>
                  </Card>
                );
              })}
            </Slider>
          ) : (
            <ContentSkeletLoader value={[""]} />
          )}
        </CardsMobile>
      </Content>
    </Wrapper>
  );
};

export default SpecialOffers;
