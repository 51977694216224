import {useContext} from 'react';
import { NewsContentContext } from "../context/NewsContentProvider";


export default function useNewsContent() {
    const {news, setNewsContent} = useContext(NewsContentContext);

    return {
        newsContent: news, setNewsContent,
    };
}
