import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { Button } from "../UI/CommonClasses";
import { ProductSkeleton } from "../Cover/Cover";
import useLoading from "../../hooks/useLoading";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 130px;
`;

const Content = styled.div`
  width: 1120px;
  justify-content: space-between;
  display: flex;
  color: #fcfcfd;
  flex-direction: row;
  background: #f7f9fe;
  border-radius: 24px;
  height: 512px;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin: 32px 32px 32px 32px;
    padding: 32px 24px 32px 24px;
    height: unset;
  }
`;

const RightContent = styled.div`
  padding-left: 64px;
  flex-grow: 1;
  flex-basis: 0;

  @media (max-width: 768px) {
    padding-left: unset;
  }
`;

const TopTitle = styled.span`
  margin-bottom: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #141416;
  background: #ffd400;
  border-radius: 4px;
  padding: 6px 12px;
  width: max-content;

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;

const Subtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #353945;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Title = styled.div`
  font-family: Nexa;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;

  letter-spacing: -0.01em;
  color: #03153d;
  padding-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 24px;
    width: unset;
    line-height: 120%;
  }
`;

const LeftContent = styled.div`
  color: black;
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  height: 100%;
`;

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 24px 0px 0px 24px;
`;
const ContentSkeletLoader = () => {
  return (
    <Content>
      <LeftContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ProductSkeleton height="200px" />
      </LeftContent>
      <RightContent
        style={{
          width: "100%",
        }}
      >
        <Title>
          <ProductSkeleton height="30px" />
        </Title>
        <Subtitle>
          <ProductSkeleton />
        </Subtitle>
        <ProductSkeleton height="50px" />
      </RightContent>
    </Content>
  );
};
const POSTerminalBaner = ({ terminal }) => {
  const { isLoading } = useLoading();
  useEffect(() => {
    AOS.init({ duration: 1500 });
    AOS.refresh();
  }, []);

  return (
    <Wrapper data-aos="fade-up" data-aos-duration="500">
      {isLoading && terminal ? (
        <Content>
          <LeftContent>
            <Img src={terminal.image} />
          </LeftContent>
          <RightContent>
            <TopTitle>{terminal.tags}</TopTitle>
            <Title>{terminal.title}</Title>
            <Subtitle>{terminal.description}</Subtitle>
            <Button
              onClick={() => {
                if (terminal.button_link)
                  return window.open(terminal.button_link, "_self");
              }}
            >
              {terminal.button_title}
            </Button>
          </RightContent>
        </Content>
      ) : (
        <ContentSkeletLoader />
      )}
    </Wrapper>
  );
};

export default POSTerminalBaner;
