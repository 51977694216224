import React, {useEffect} from 'react';
import styled from 'styled-components';
import AOS from "aos";
import "aos/dist/aos.css";
import {replaceWithBr} from "../../Shared/sharedFunction";


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  height: 100%;

  background-color:${(props)=>{
    return props.bg ?? '#FFFFFF';
  }} ;
  color:${(props)=>{
    return props.colorTxt ?? 'black';
  }};
`;

const Content = styled.div`
  width: 1120px;
  height: 100%;
  padding: 128px 64px;
  margin: ${(props)=>{
    if(props.border){
      return `64px 0`;
    }
    return 'none';
  }};;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;

  border:${(props)=>{
      if(props.border){
          return `1px solid ${props.border_color}`;
      }
    return 'none';
  }};
  
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding:${(props)=>{
      if(props.border){
        return `40px 24px`;
      }
      return '64px 32px 64px 32px';
    }};; 
            
            
            
    height: unset;

    margin: ${(props)=>{
      if(props.border){
        return `16px 32px`;
      }
      return 'none';
    }};;
  }
`;
const Title= styled.div`
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  padding-bottom: 16px;
  

  @media (max-width: 768px) {
    font-size: 32px;
  }
`
const SubTitle= styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-align: center;
  letter-spacing: -0.01em;
  max-width:${(props)=>{
    if(props.border){
      return `unset !important`;
    }else return '733px';

  }};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const Icon = styled.img`
  width: 72px;
  height: 72px;
  padding-bottom: 40px;
`

const IconHorizontal = styled.img`
  width: 177px;
  height: 177px;
  padding-right: 64px;
  
  @media (max-width: 768px) {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 32px;
  }
`
const BlockHorizontal = styled.div`
  padding: 78px 64px;
  @media (max-width: 768px) {
    padding: 0;
  }
`

const ContentHorizontal = styled.div`
  width: 1120px;
  height: 100%;
  
  background-color:${(props)=>{
    return props.bg ?? '#FFFFFF';
  }} ;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  border: none;
  

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    padding:${(props)=>{
      if(props.border){
        return `40px 24px`;
      }
      return '64px 32px 64px 32px';
    }};
    height: unset;
    margin: 16px 32px
  }

`

const TextBlock = ({parameters})=>{

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);



    if(parameters.type==="vertical"){
        return (
            <Wrapper  data-aos='fade-up' data-aos-duration="500" bg={parameters.background_color} colorTxt={parameters.text_color}>
                <Content border={!!parameters.is_bordered} border_color={parameters.border_color}>
                    {parameters.icon? <Icon src={parameters.icon}/>:null}
                    {parameters.title?   <Title>{parameters.title}</Title>:null}
                    {parameters.description?  <SubTitle  border={!!parameters.border_color} dangerouslySetInnerHTML={{__html: replaceWithBr(parameters.description)}}/>:null}
                </Content>
            </Wrapper>
        )
    }
    else {
        return(
            <Wrapper   data-aos='fade-up' data-aos-duration="500" colorTxt={parameters.text_color}>
                <ContentHorizontal  bg={parameters.background_color} >

             <BlockHorizontal>
                 {parameters.title?   <Title style={{textAlign:'unset',fontSize:'32px'}}>{parameters.title}</Title>:null}
                 {parameters.description?  <SubTitle style={{textAlign:'unset', maxWidth:parameters.icon?'675px':'unset'}}  dangerouslySetInnerHTML={{__html: replaceWithBr(parameters.description)}}/>:null}
             </BlockHorizontal>
                    {parameters.icon? <IconHorizontal  src={parameters.icon} />:null}
                </ContentHorizontal>
            </Wrapper>
        )
    }
}

export default TextBlock