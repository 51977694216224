import React, {useEffect} from 'react';
import styled from 'styled-components';
import AOS from "aos";
import "aos/dist/aos.css";
import logo from '../../img/logoWhite.png'
import playMarketLogo from "../../img/playMarket.png"
import appStoreLogo from "../../img/appStore.png"
import {Icons} from '../../Icons/Icons'
import {useTranslation} from "react-i18next";

const FooterWrapper = styled.div`
  background: #03153D;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 32px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterContent = styled.div`
  width: 1120px;
  display: flex;
  color: #FCFCFD;
  flex-direction: column;
  @media (max-width: 768px) {
    width: unset;
  }


`;

const ContentWrapper = styled.div`
    display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 32px;
  }
`;

const ContentBlock = styled.div`
    display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
  }

`;

const BoldText = styled.a`
  display: inline-block;
  text-decoration: none;
  color: #FCFCFD;
  font-weight: bold;
`;

const Text = styled.span`
  color: #777E91;
  font-size: 14px;
`;

const TextWrapper = styled.span`
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const Link = styled.span`
  cursor: pointer;
  padding: 8px 0;
  display: flex;
  flex-direction: row;
`;


const ContentDivider = styled.div`
    width: 100%;
  border-bottom: 1px solid #353945;
  margin: 32px 0;

  @media (max-width: 768px) {
   padding: 0 32px;
    display: none;
  }

`;

const Logo = styled.div`
    height: 25px;
  padding-bottom: 38px;

  .logo{
    height: 25px;
  }
`;

const ImgLogo = styled.img`
  height: 50px;
  object-fit: cover;
  padding-right: 16px;
`;

const Footer = (props)=>{
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return(
        <FooterWrapper data-aos='fade-up' data-aos-duration="500">
            <FooterContent>
              <ContentWrapper>
                  <ContentBlock>
                      <TextWrapper>
                          <BoldText href={`tel:${props.footerInfo?.filter(el=> el.type==="mobile")[0]?.content}`}> {props.footerInfo?.filter(el=> el.type==="mobile")[0]?.content} </BoldText> <Text> - {t('Calling from a cell phone is free of charge')}</Text>
                      </TextWrapper>
                      <TextWrapper>
                          <BoldText href={`http://api.whatsapp.com/send?phone=${props.footerInfo?.filter(el=> el.type==="whatsapp")[0]?.content.match(/\d/g).join('')}`} target="_blank">{props.footerInfo?.filter(el=> el.type==="whatsapp")[0]?.content} </BoldText>
                          <Text>- {t('Send on WhatsApp')}</Text>
                      </TextWrapper>
                      <TextWrapper>
                          <BoldText href={`tel:${props.footerInfo?.filter(el=> el.type==="phone")[0]?.content}`}>{props.footerInfo?.filter(el=> el.type==="phone")[0]?.content}</BoldText>
                      </TextWrapper>

                  </ContentBlock>
                  <ContentBlock>
                      <TextWrapper>
                          <Text>
                          720021, {t('Kyrgyz Republic')},
                          </Text>
                      </TextWrapper>
                      <TextWrapper>
                          <Text> {t('address')}</Text>
                      </TextWrapper>


                  </ContentBlock>
                  <ContentBlock>
                      <TextWrapper>
                          <Text>
                              {t('Mobile banking')}
                          </Text>
                      </TextWrapper>
                      <TextWrapper>
                          <div style={{display:'flex', flexDirection:'row'}}>
                              <ImgLogo src={playMarketLogo} onClick={()=>window.open(props.footerInfo?.filter(el=> el.type==="google-play")[0]?.content, '_blank')}/>
                              <ImgLogo src={appStoreLogo} onClick={()=>window.open(props.footerInfo?.filter(el=> el.type==="app-store")[0]?.content, '_blank')}/>
                          </div>
                      </TextWrapper>
                  </ContentBlock>

              </ContentWrapper>
                <ContentDivider/>
              <ContentWrapper>
                  {props.footerLink.map((item,index)=> {
                      return <ContentBlock key={index+Math.random()}>
                          {item.title === t('Main') ?
                          (    <Logo>
                                  <img className={'logo'} src={logo} alt={'Kyrgyzkommertsbank'}/>
                              </Logo>)
                              :
                         (     <TextWrapper>
                                  <BoldText>{item.title}</BoldText>
                              </TextWrapper>)
                          }
                          {item.subs.map(
                              (item, i) => {
                                  return <Link key={i + Math.random()}>
                                      <Text onClick={()=>window.open(item?.link, "_self")}>{item.title}</Text>
                                  </Link>
                              }
                          )}
                          {item.title === t('Main') ?
                              ( <Link>
                                  {props.footerInfo?.filter(el=> el.type==='instagram'||el.type==='twitter'||el.type==='youtube'||el.type==='fb').map(el=>{
                                      return <div onClick={()=>window.open(el.content, '_blank')} style={{paddingRight:'16px'}} key={Math.random()}>{Icons[el.type]}</div>
                                  })}
                              </Link>)
                              :
                             null
                          }
                      </ContentBlock>
                  })}
              </ContentWrapper>
                <ContentDivider/>
              <ContentWrapper>
                  <TextWrapper>
                      <Text>
                          {t('credits')}
                      </Text>
                  </TextWrapper>
              </ContentWrapper>
            </FooterContent>
        </FooterWrapper>
    )
}

export default Footer
