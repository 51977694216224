import React, { createContext, useState } from 'react';


export const ModuleContext = createContext(null);

export const ModuleProvider = ({ children }) => {
    const [isModuleOpen, setModuleOpen] = useState(false);

    const setModule = (moduleState)=> {
        setModuleOpen(moduleState);
    };

    return (
        <ModuleContext.Provider
            value={{
                isModuleOpen,
                setModule,
            }}>
            {children}
        </ModuleContext.Provider>
    );
};
