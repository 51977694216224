import axios from 'axios';

const axiosApiInstance = axios.create();
const MAIN_URL = 'https://admin.kkb.kg/api';

class DataService {

    async getHome() {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };
        const url = `${MAIN_URL}/home`;
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async getParts() {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = `${MAIN_URL}/parts`;
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async getAllNews(page) {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = `${MAIN_URL}/news${page?'?page='+page:''}`;
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async getRegion(){
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = `${MAIN_URL}/regions`;
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async getMaps(typeMap,filter){
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        function isBoolean(value) {
            return typeof value === "boolean";
        }

        let filterString='?';
        Object.keys(filter.filter).forEach(el=> {
            let item=null;
            if(el==='currency_type' && filter.currency_id){
                item='currency_id='+filter.filter['currency_id'];
                filterString = filterString  + item+ '&';
            }
            else if(el==='currency_type'){
                item=''
            }
            else{
                item =
                    isBoolean(filter.filter[el]) ? ( filter.filter[el] ? 1 : 0) : filter.filter[el]
                ;
                filterString = filterString  + el + '=' + item+ '&';
            }


        })

        const url = `${MAIN_URL}/${typeMap}${filterString}`;
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async getNewsByTitle(title) {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = `${MAIN_URL}/news/${title}`;
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async getCurrency() {
        const headers = {
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = 'https://admin.kkb.kg/api/currencies';
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async getBranches() {
        const headers = {
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = 'https://admin.kkb.kg/api/offices';
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async getSubdivisions() {
        const headers = {
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = 'https://admin.kkb.kg/api/subdivisions';
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }


}

export const dataService = new DataService();
