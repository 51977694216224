import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import AOS from "aos";
import {ContentLogic} from "../../../pages/Constructor";
import {dataService} from "../../../services/getConstructorContent.service";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  height: 100%;
  margin-top: 0px;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 1120px;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding: 0 24px 0 24px;
    height: unset;
  }
`;
const Content2 = styled.div`
  width: 1120px;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  
  border-bottom: 1px solid #E6E8EC;
  
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    padding: 0 24px 0 24px;
    height: unset;
  }
`;

const Item = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #777E91;
  padding-right: 40px;
  cursor: pointer;
  padding: 16px;
  
  &.active{
    
    color: #03153D;
    border-bottom: 2px solid #0029D3;
  }
`

const Tabs = ({paramters})=>{
    const [blocks, setBlocks]=useState([]);
    const [tabItems, setTabItems]=useState([]);

    const [openMenu, setOpenMenu]=useState('')

    useEffect(()=>{
        (async function (){
            let promises =   paramters.items.map(el=> dataService.getContent(el.child_id).then(data=>data.data));
            let blocks = (await Promise.all(promises)).flat();
            setBlocks(blocks.sort(function(a, b) {
                return a.order_id - b.order_id;
            }));
            setOpenMenu(paramters.items[0].child_id);
            setTabItems(paramters.items);
        })();
    },[paramters.id])
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return <Wrapper>
        <Content2>
            {tabItems.map(el=>
                <Item className={el.child_id===openMenu?'active':''} onClick={()=>setOpenMenu(el.child_id)}>{el.title}</Item>
                )}
        </Content2>


        <Content>
            {blocks.filter(el=>el.id===openMenu).map(e=> {
                return <ContentLogic blocks={e.blocks}/>
            })}
        </Content>
    </Wrapper>
}

export default Tabs;