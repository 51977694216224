import React, {useEffect, useState} from 'react';
import { Button } from '../UI/CommonClasses'
import styled from 'styled-components';
import AOS from "aos";
import "aos/dist/aos.css";
import {Icons} from '../../Icons/Icons'
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const MenuContent = styled.div`
  width: 1120px;
  padding: 40px;
  background: #FCFCFD;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  border-radius: 24px;
  z-index: 1000;
  margin-top: 8px;


  @media (max-width: 768px) {
    display: none;
    height: 100vh;

  }

`
const MenuWrapper = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  margin-bottom: 32px;
  top:96px;
  min-height: 632px;
  z-index: 10000;
  bottom: 0;
  max-height: 632px;

  @media (max-width: 768px) {
    max-height: unset;
    height: 100%;
    top: 60px;
    padding: 0;
  }
`
const Background= styled.div`
position: absolute;
top:0;
  bottom: 0;
  left: 0;
  right: 0;
`

const MenuTitle = styled.div`
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;

  letter-spacing: -0.01em;

  color: #03153D;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    
    margin-top: 32px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 16px;
    border: 2px solid #E6E8EC;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #03153D;
  }
`

const SubMenu = styled.div`
display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap:wrap;
`


const SubMenuBlock = styled.div`
    padding-bottom: 24px;
  max-width: 350px;
  
  .active{
    color: #0068FF;
  }
`


const MenuContentMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    background: #FCFCFD;
    position: absolute;
    left: 0;
    right: 0;
    min-height: 100vh;
    height: max-content;
    top:0;
    padding: 0;
    z-index: 1000;
    padding: 32px;
    padding-top: 50px;
    .active{
      color: #0068FF;
    }
  }
`


const ActionMobile = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`


const SelectMobile = styled.div`
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`
const SelectOptions = styled.div`
  @media (max-width: 768px) {
    background: #FCFCFD;
    border: 2px solid #E6E8EC;
    box-sizing: border-box;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 12px;
  }
`
const Option = styled.a`
  @media (max-width: 768px) {
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #03153D;
  }
`


const MenuMobile = styled.div`
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`


const SubMenuText = styled.a`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #777E91;
  padding: 4px 0;
  cursor: pointer;
  display: block;
  text-decoration: none;

  @media (max-width: 768px) {
    padding: 8px 24px;
  }
  
`

const IconsStyle = styled.div`
    margin-top: 3px;
`;

const Button2 = styled.div`
  padding: 12px 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #23262F;
  border: 2px solid #E6E8EC;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  margin-left: 12px;
`

const ButtonSmall = styled.div`
  padding: 8px 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #23262F;
  border: 2px solid #E6E8EC;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
`

const SubMenuTitle = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #03153D;
  padding: 4px 0;

  @media (max-width: 768px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

`


const Menu = (props)=>{
    const [category, setCategory]= useState(null)
    const [openSelect, setOpenSelect]= useState(false)
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return(
        <MenuWrapper data-aos='fade-down' data-aos-duration="500" style={{position:'fixed', overflowY:'auto'}}>
            <MenuContent>
                <MenuTitle>{props?.menuContent[0]?.title}</MenuTitle>
                <SubMenu>
                    {props?.menuContent[0]?.subs.map((item,index)=>{
                        return(<SubMenuBlock key={index+Math.random()}>
                            <SubMenuTitle href={item.link}>{item?.title}</SubMenuTitle>
                            {item?.subs?.map((e,i)=>{
                                return <SubMenuText key={i+Math.random()} href={e.link}>{e?.title}</SubMenuText>
                            })}
                            </SubMenuBlock>)
                    })}
                </SubMenu>
            </MenuContent>
            <MenuContentMobile>
                <ActionMobile>
                    {/*<Search>{Icons.search}</Search>*/}
                    <Button onClick={()=>window.open('https://kkb24.kg/', "_self")}>Интернет-банкинг</Button>
                    <Button2 onClick={()=>window.open('https://videobank.kkb.kg/', "_self")}>
                        <IconsStyle> {Icons.videoBlue}</IconsStyle>
                    </Button2>
                </ActionMobile>
                <SelectMobile>
                    <MenuTitle onClick={()=>setOpenSelect(true)}>
                        <span>{props?.menuContent[0]?.title}</span>

                    <span>{Icons.arrowBottom}</span></MenuTitle>
                    {openSelect? <SelectOptions>
                        { props?.menu?.map((item,index)=>(<Option href={item.link} onClick={(e)=> {
                            e.preventDefault();
                            if(item.subs.length===0 && item.link){
                                return window.open(item.link, "_self")
                            } else {
                                props.setPart(item.id);
                                setOpenSelect(false)
                            }
                        }}  className={props?.menuContent[0]?.title===item?.title?'active':''}>{item.title}</Option> )) }
                    </SelectOptions>:null}

                </SelectMobile>

                <ButtonSmall onClick={()=>history.push('/currency/')}>
                    {t('Exchange rates')}
                </ButtonSmall>

                <MenuMobile>
                    <SubMenu>
                        {props?.menuContent[0]?.subs.map((item,index)=>{
                            return(<SubMenuBlock key={index+Math.random()}>

                                <SubMenuTitle href={item.link} onClick={(e)=> {
                                    e.preventDefault();
                                    if(item?.subs?.length===0 && item.link){
                                        return window.open(item.link, "_self")
                                    } else return category===item?.title ? setCategory(null) : setCategory(item?.title)
                                }}
                                              className={category===item?.title?'active':''}>
                                    <span> {item?.title}</span>
                                    {item?.subs.length!==0
                                        ?
                                        <span>{category===item?.title?Icons.arrowTop:Icons.arrowBottom}</span>
                                        :null}


                                </SubMenuTitle>
                                {category===item?.title ? item?.subs?.map((e,i)=>{
                                    return <SubMenuText key={i+Math.random()}  href={e.link}>{e?.title}</SubMenuText>
                                }):null}
                            </SubMenuBlock>)
                        })}
                    </SubMenu>
                </MenuMobile>
            </MenuContentMobile>
            <Background onClick={()=> {
                props.setOpenMenu(false);
                props.setPart(null);
            }}/>
        </MenuWrapper>

    )
}

export default Menu
