import React, {useEffect} from 'react';
import styled from 'styled-components';
import AOS from "aos";
import "aos/dist/aos.css";
import {Button} from "../../UI/CommonClasses";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowSize from "../../../hooks/useWindowSize";


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  height: 100%;
  margin-top: 0;

  background-color:${(props)=>{
    return props.bg ?? '#FFFFFF';
  }};
  color:${(props)=>{
    return props.colorTxt ?? 'black';
  }};
`;

const Content = styled.div`
  width: 996px;
  height: 100%;
  padding: 64px 64px;

  margin: ${(props)=>{
    if(props.border){
        return `32px 0`;
    }
    return 'none';
}};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;

  border:${(props)=>{
    if(props.border){
        return `1px solid ${props.border_color}`;
    }
    return 'none';
}};
  
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    padding:${(props)=>{
    if(props.border){
        return `40px 24px`;
    }
    return '64px 32px 64px 32px';
}};
            
            
            
    height: unset;

    margin: ${(props)=>{
    if(props.border){
        return `16px 32px`;
    }
    return 'none';
}};
  }
`;
// const Title= styled.div`
//   font-family: 'Nexa';
//   font-style: normal;
//   font-weight: 900;
//   font-size: 48px;
//   line-height: 56px;
//   text-align: center;
//   letter-spacing: -0.02em;
//   text-transform: uppercase;
//   padding-bottom: 16px;
//
//   @media (max-width: 768px) {
//     font-size: 32px;
//   }
// `
/*const SubTitle= styled.div`
  max-width:${(props)=>{
    if(props.border){
        return `unset !important`;
    }else return '733px';

}};

  @media (max-width: 768px) {
    font-size: 16px;
  }
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  text-align: center;
  letter-spacing: -0.01em;


`

const Icon = styled.img`
  width: 72px;
  height: 72px;
  padding-bottom: 40px;

`
const BlockHorizontal = styled.div`


`
const ContentHorizontal = styled.div`
  width: 1120px;
  height: 100%;
  padding: 78px 64px;
  background-color:${(props)=>{
    return props.bg ?? '#FFFFFF';
}} ;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  border: none;


  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    padding:${(props)=>{
    if(props.border){
        return `40px 24px`;
    }
    return '64px 32px 64px 32px';
}};;



    height: unset;

    margin: 16px 32px
  }

`
*/
const RightContent = styled.div`
  margin: 0 64px;
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;

  @media (max-width: 768px) {
    padding-left: unset;
    width: 85%;
  }
`;


const TopTitle = styled.span`

  margin-bottom: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #141416;
  background: #FFD400;
  border-radius: 4px;
  padding: 6px 12px;
  width: max-content;

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;

const Subtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 14px;
  }

`;

const Title = styled.div`
  font-family: Nexa;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;

  letter-spacing: -0.01em;
  padding-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 24px;
    width: unset;
    line-height: 120%;
  }
`;

const  LeftContent = styled.div`
  
  flex-grow: 1;
  flex-basis: 0;
  width:100%;
  height: 100%;
  display: grid;
  column-gap: 25px;
  row-gap: 25px;
  justify-content: space-between;
  padding-top: 64px;
  grid-template-columns: ${(props)=>{
    if(!props.itemNumber){
      return `1fr 1fr 1fr`;
    }
    return ` repeat(${props.itemNumber}, auto)`;
  }};;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
`;

const LeftContentType2 = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 40px;
  }
`;

const LeftContentType3 = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Item = styled.div`
    display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
  padding-bottom: 40px;
  }
`

const ItemType2 = styled.div`
    display: flex;
  flex-direction: column;
  background: #F7F9FE;
  border-radius: 24px;
  padding: 32px;
  margin-bottom: 24px;
`

const ItemType3 = styled.div`
    display: flex;
  flex-direction: column;
  width: 100%;
  background: #F7F9FE;
  border-radius: 24px;
  padding: 32px;
  margin-right: 32px;
  height: 100%;
`
const ItemTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #141416;
  @media (max-width: 768px) {
    padding-bottom: 8px;
  }
`

const ItemSubtitle = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #777E91;
`

const ImgWrapper= styled.div`
  width: 100%;
`

const Img= styled.img`
  width: 64px;
  padding-top: 32px;
  padding-bottom: 32px;
`

const CardsMobile= styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`


const Card = styled.div`
  cursor: pointer;
  width: 352px !important;
  height: 364px;
  border: 1px solid #E6E8EC;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
  margin:16px;
  margin-bottom: 128px;
  transition: all .5s ease-in-out;
  position: relative;
  background-color:${(props)=>{
    return props.bg ?? '#F7F9FE';
}} ;
  color:${(props)=>{
    return props.colorTxt ?? 'black';
}};
  .arrow{
    display: none;
    position: absolute;
    bottom: 32px;
    right: 32px;
  }
  &:hover {
    box-shadow: 0 64px 64px -48px rgba(15, 15, 15, 0.1);
    .arrow{
      display: block;
    }
    
  }

  @media (max-width: 768px) {
    display: none;
  }
  
`

const CardTitle= styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 16px;
  padding-top: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const CardSubtitle= styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`


const InfoBlock = ({parameters})=>{
    const wind=useWindowSize();

    function replaceWithBr(text) {
        if(text) return text.replace(/\n/g, "<br />");
    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);



    if(
        parameters.type==="1"||
        parameters.type==="блок функций(нижняя)"
    ){
        return (
            <Wrapper data-aos='fade-up' data-aos-duration="500">
                <Content bg={parameters.bg_color} border={true} border_color={"#E6E8EC"} colorTxt={parameters?.text_color} style={{flexDirection:wind.isDesktop?'reverse-column':'column', alignItems:'left !important'}}>
                    <RightContent>
                        {/*<TopTitle>{parameters.tag}</TopTitle>*/}
                        <Title>{parameters.title}</Title>
                        <Subtitle  dangerouslySetInnerHTML={{__html: replaceWithBr(parameters.description)}}/>
                        {parameters.button1_link && <Button onClick={()=> {
                            if(parameters.button1_link) return window.open(parameters.button1_link, "_self")
                        }} Modal={!!parameters.button1_modal}>{parameters.button1_text}</Button>}
                        {/*<Button onClick={()=>window.open(parameters.button1_link)}>{parameters.button1_text}</Button>*/}
                    </RightContent>
                    {parameters.items.length > 0 &&
                    <LeftContent style={parameters.items.length>=6&&wind.isDesktop?{display:'flex',flexDirection: 'column',justifyContent:'space-between'}:{}} itemNumber={parameters.items.length}>
                        {parameters.items.sort((a,b) => {return a.order_id - b.order_id}).map(item=>{
                            return(<Item onClick={()=> {
                                if(item.link) return window.open(item.link, "_self")
                            }}
                                         style={parameters.items.length>=6&&wind.isDesktop?{ flexDirection: 'row', justifyContent:'space-between',paddingBottom:'24px'}:{}}
                            >
                                <ItemTitle>{item.title}</ItemTitle>
                                <ItemSubtitle dangerouslySetInnerHTML={{__html: replaceWithBr(item.description)}} style={parameters.items.length>=6&&wind.isDesktop?{textAlign:'end'}:{}}/>
                            </Item>)
                        })}
                    </LeftContent>}
                </Content>
            </Wrapper>
        )
    }
    else if(
        parameters.type==="2"||
        parameters.type==="блок функций(правая)"
    ) {
        return(
            <Wrapper data-aos='fade-up' data-aos-duration="500" >
                <Content  colorTxt={parameters?.text_color} style={{flexDirection:wind.isDesktop?'row-reverse':'column-reverse'}}>
                    <LeftContentType2>
                        {parameters.items.map(item=>{
                            return(   <ItemType2 onClick={()=> {
                                if(item.link) return window.open(item.link, "_self")
                            }}>
                                <ItemTitle>{item.title}</ItemTitle>
                                <ItemSubtitle dangerouslySetInnerHTML={{__html: replaceWithBr(item.description)}}/>
                            </ItemType2>)
                        })}
                    </LeftContentType2>
                    <RightContent>
                        {parameters.tag?<TopTitle>{parameters.tag}</TopTitle>:null}
                        <Title>{parameters.title}</Title>
                        <Subtitle dangerouslySetInnerHTML={{__html: replaceWithBr(parameters.description)}}/>
                        {parameters.button1_link && <Button style={{marginTop: '40px'}} onClick={()=> {
                            if(parameters.button1_link) return window.open(parameters.button1_link, "_self")
                        }} Modal={!!parameters.button1_modal}>{parameters.button1_text}</Button>}
                    </RightContent>
                </Content>
            </Wrapper>

        )
    }
    else if(
        parameters.type==="3"||
        parameters.type==="блок функций левая"
    ){
        const settingsMobile={
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        }
        return (
            <Wrapper data-aos='fade-up' data-aos-duration="500" >
                <Content  colorTxt={parameters?.text_color} style={{flexDirection:wind.isDesktop?'row':'column-reverse'}}>
                    <CardsMobile>
                        <Slider {...settingsMobile}>
                            {parameters.items?.map((e,i)=>{
                                return(
                                    <Card key={i+Math.random()} >
                                        <ImgWrapper>
                                            {e.image?<Img src={e.image}/>:null}
                                        </ImgWrapper>
                                        <CardTitle>{e.title}</CardTitle>
                                        <CardSubtitle dangerouslySetInnerHTML={{__html: replaceWithBr(e.description)}}/>
                                    </Card>
                                )
                            })}
                        </Slider>
                    </CardsMobile>
                    <LeftContentType3>
                        {parameters.items?.map(item=>{
                            return(   <ItemType3 onClick={()=> {
                                if(item.link) return window.open(item.link, "_self")
                            }}>
                                <ImgWrapper>
                                    {item.image?<Img src={item.image}/>:null}
                                </ImgWrapper>
                                <ItemTitle>{item.title}</ItemTitle>
                                <ItemSubtitle dangerouslySetInnerHTML={{__html: replaceWithBr(item.description)}}/>
                            </ItemType3>)
                        })}
                    </LeftContentType3>
                    <RightContent>
                        {parameters.tag?<TopTitle>{parameters.tag}</TopTitle>:null}
                        <Title>{parameters.title}</Title>
                        <Subtitle  dangerouslySetInnerHTML={{__html: replaceWithBr(parameters.description)}}/>
                        {parameters.button1_link && <Button style={{marginTop: '40px'}} onClick={()=> {
                            if(parameters.button1_link) return window.open(parameters.button1_link, "_self")
                        }}
                        >{parameters.button1_text}</Button>}
                    </RightContent>
                </Content>
            </Wrapper>
        )
    }
}

export default InfoBlock;
