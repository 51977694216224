import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "All": "All",
            "Currency": "Currency",
            "Round the clock": "Round the clock",
            "Working hours": "Working hours",
            "break": "break",
            "Main": "Main",
            "ATMs": "ATMs",
            "Offices": "Offices",
            "On the map": "On the map",
            "Listed by": "Listed by",
            "ATM type": "ATM type",
            "Currency of money acceptance": "Currency of money acceptance",
            "Currency of money disbursement": "Currency of money disbursement",
            "ATM number": "ATM number",
            "Popular products": "Popular products",
            "Calling from a cell phone is free of charge": "Calling from a cell phone is free of charge",
            "Send on WhatsApp": "Send on WhatsApp",
            "Kyrgyz Republic": "Kyrgyz Republic",
            "address": "Bishkek, 101 Shopokov St.",
            "Mobile banking": "Mobile banking",
            "credits": "© Kyrgyzkommertsbank 2021 — All rights reserved. License of the NBKR No.010, No.010/1",
            "Exchange rates": "Exchange rates",
            "News": "News",
            "Gold rates": "Gold rates",
            "Cash": "Cash",
            "Non-cash": "Non-cash",
            "Payment cards": "Payment cards",
            "NBKR rates": "NBKR rates",
            "complaintsText": "The procedure for considering complaints and suggestions",
            "contactsText": "Contacts of NBKR departments for applications",
            "currenciesText": "In accordance with the bank's tariff policy for individual clients, the bank may consider the establishment of individual exchange rate.",
            "Currency pairs": "Currency pairs",
            "Buy": "Buy",
            "Sell": "Sell",
            "Value (g)": "Value (g)",
            "Special offers for business": "Special offers for business",
            "g.": "g.",
            "Main phone": "Main phone",
            "Fax": "Fax",
            "Working days": "Working days",
            "Region": "Region",
            "Bishkek": "Bishkek",
            "Chui oblast": "Chui oblast",
            "Issyk-Kul oblast": "Issyk-Kul oblast",
            "Naryn oblast": "Naryn oblast",
            "Batken oblast": "Batken oblast",
            "Osh oblast": "Osh oblast",
            "Talas oblast": "Talas oblast",
            "Dzhalal-Abad oblast": "Dzhalal-Abad oblast",
            "Osh": "Osh",
            "TSUM branch": "TSUM branch",
            "Hide filter": "Hide filter",
            "Your application is accepted": "Your application is accepted",
            "Our specialists will contact you shortly": "Наши специалисты в скором времени свяжутся с вами",
            "Some error happened!": "Some error happened!",
            "We're sorry, seems like something went wrong. Please, call to contact center or try again.": "We're sorry, seems like something went wrong. Please, call to contact center or try again.",
        }
    },
    ru: {
        translation: {
            "All": "Все",
            "Currency": "Валюта",
            "Round the clock": "Круглосуточно",
            "Working hours": "Время работы",
            "break": "перерыв",
            "Main": "Главная",
            "ATMs": "Банкоматы",
            "Offices": "Офисы",
            "On the map": "На карте",
            "Listed by": "Списком",
            "ATM type": "Тип банкомата",
            "Currency of money acceptance": "Валюта приёма денег",
            "Currency of money disbursement": "Валюта выдачи денег",
            "ATM number": "Номер банкомата",
            "Popular products": "Популярные продукты",
            "Calling from a cell phone is free of charge": "Звонок с мобильного бесплатный",
            "Send on WhatsApp": "Написать в WhatsApp",
            "Kyrgyz Republic": "Кыргызская Республика",
            "address": "г.Бишкек, ул.Шопокова, 101",
            "Mobile banking": "Мобильный банкинг",
            "credits": "© Kyrgyzkommertsbank 2021 — Все права защищены. Лицензия НБКР №010, №010/1",
            "Exchange rates": "Курсы валют",
            "News": "Новости",
            "Gold rates": "Курсы золота",
            "Cash": "Наличные",
            "Non-cash": "Безналичные",
            "Payment cards": "Платежные карты",
            "NBKR rates": "Курсы НБКР",
            "complaintsText": "Порядок рассмотрения жалоб и предложений",
            "contactsText": "Контакты подразделений НБКР для обращений",
            "currenciesText": "В соответствии с тарифной политикой Банка для отдельных клиентов Банк может рассмотреть возможность установления индивидуального курса валют.",
            "Currency pairs": "Пары валют",
            "Buy": "Покупка",
            "Sell": "Продажа",
            "Value (g)": "Величина (гр.)",
            "Special offers for business": "Специальные предложения для бизнеса",
            "g.": "гр.",
            "Main phone": "Основной телефон",
            "Fax": "Факс",
            "Working days": "Режим работы",
            "Region": "Регион",
            "Bishkek": "г. Бишкек",
            "Chui oblast": "Чуйская область",
            "Issyk-Kul oblast": "Иссык-Кульская область",
            "Naryn oblast": "Нарынская область",
            "Batken oblast": "Баткенская область",
            "Osh oblast": "Ошская область",
            "Talas oblast": "Таласская область",
            "Dzhalal-Abad oblast": "Джалал-Абадская область",
            "Osh": "г. Ош",
            "TSUM branch": "Филиал 'ЦУМ'",
            "Hide filter": "Скрыть фильтр",
            "Your application is accepted": "Ваша заявка принята",
            "Our specialists will contact you shortly": "Наши специалисты в скором времени свяжутся с вами",
            "Some error happened!": "Произошла ошибка!",
            "We're sorry, seems like something went wrong. Please, call to contact center or try again.": "Извините, произошла ошибка при отправке заявки. Пожалуйста, свяжитесь с контактным центром или попробуйте еще раз.",
        }
    },
    kg: {
        translation: {
            "All": "Баары",
            "Currency": "Валюта",
            "Round the clock": "Күнү-түнү",
            "Working hours": "Иштөө убактысы",
            "break": "тыныгуу",
            "Main": "Башкы бет",
            "ATMs": "Банкоматтар",
            "Offices": "Кеңселер",
            "On the map": "Картада",
            "Listed by": "Тизме менен",
            "ATM type": "Банкоматтын түрү",
            "Currency of money acceptance": "Акчаны кабыл алуу валютасы",
            "Currency of money disbursement": "Акчаны берүү валютасы",
            "ATM number": "Банкомат номери",
            "Popular products": "Белгилүү продукттар",
            "Calling from a cell phone is free of charge": "Мобилдик телефондон чалуу акысыз",
            "Send on WhatsApp": "WhatsAppка жазуу",
            "Kyrgyz Republic": "Кыргыз Республикасы",
            "address": "Бишкек ш., Шопоков көч., 101",
            "Mobile banking": "Мобилдик банкинг",
            "credits": "© Kyrgyzkommertsbank 2021 — Бардык укуктар корголгон. КРУБ лицензиясы №010, №010/1",
            "Exchange rates": "Валюталар курсу",
            "News": "Жаңылыктар",
            "Gold rates": "Алтын курсу",
            "Cash": "Нак акча каражаттары",
            "Non-cash": "Нак эмес каражаттар",
            "Payment cards": "Төлөм карттары",
            "NBKR rates": "КРУБ курсу",
            "complaintsText": "Арыздарды жана сунуштарды кароонун тартиби",
            "contactsText": "Арыздар үчүн Улуттук банктын бөлүмдөрүнүн байланыштары",
            "currenciesText": "Банктын тарифтик саясатына ылайык айрым кардарлар үчүн Банк жеке валюта курсун белгилөө мүмкүнчүлүгүн карайт.",
            "Currency pairs": "Валюта жуптары",
            "Buy": "Сатып алуу",
            "Sell": "Сатуу",
            "Value (g)": "Өлчөмү (гр.)",
            "Special offers for business": "Бизнес үчүн атайын сунуштар",
            "g.": "гр.",
            "Main phone": "Негизги телефон",
            "Fax": "Факс",
            "Working days": "Иштөө режими",
            "Region": "Аймак",
            "Bishkek": "Бишкек ш.",
            "Chui oblast": "Чүй облусу",
            "Issyk-Kul oblast": "Ысык-Көл облусу",
            "Naryn oblast": "Нарын облусу",
            "Batken oblast": "Баткен облусу",
            "Osh oblast": "Ош облусу",
            "Talas oblast": "Талас облусу",
            "Dzhalal-Abad oblast": "Жалал-Абад облусу",
            "Osh": "Ош ш.",
            "TSUM branch": "'ЦУМ' филиалы",
            "Hide filter": "Скрыть фильтр",
            "Your application is accepted": "Сиздин арызыңыз кабыл алынды",
            "Our specialists will contact you shortly": "Биздин адистер тез арада сиз менен байланышат",
            "Some error happened!": "Ката кетти!",
            "We're sorry, seems like something went wrong. Please, call to contact center or try again.": "Кечиресиз, өтүнүчүңүздү тапшырып жатканда ката кетти. Байланыш борборуна кайрылыңыз же кайталап көрүңүз.",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "ru", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
