import PuffLoader from "react-spinners/PuffLoader";

const override = {
  display: "block",
  margin: "200px auto",
};

function SpinnerLoader() {
  return (
    <div style={override}>
      <PuffLoader
        cssOverride={override}
        color={"#03153D"}
        loading={true}
        size={100}
      />
    </div>
  );
}

export default SpinnerLoader;
