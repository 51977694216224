import React, {useEffect} from 'react';
import styled from 'styled-components';
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {replaceWithBr} from "../../Shared/sharedFunction";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  height: 100%;
  margin-top: 16px;
`;

const Content = styled.div`
  width: 1120px;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    padding: 32px 24px 0 24px;
    height: unset;
  }
`;
const Title= styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  padding-bottom: 8px;
`
const SubTitle= styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`

const InfoBlock = styled.div`
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`
const Icon = styled.img`
  width: 64px;
  height: 64px;
  padding-bottom: 16px;

`

const RectangleWrapper = styled.div`
  display: grid;
  padding: 64px;
  border-radius: 24px;
  display: grid;
  row-gap: 64px;
  column-gap: 40px;
  grid-template-columns: ${(props)=>{
      let result = '';
      for(let i=0; i<props.column;i++) {
      result=result+ '1fr '
      }
    return result;
  }} ;
  background-color:${(props)=>{
    return props.bg ?? '#F7F9FE';
  }} ;
  color:${(props)=>{
    return props.colorTxt ?? 'black';
  }};

  @media (max-width: 768px) {
  display: flex;
    flex-direction: column;
 
}
`;

const TableWrapper = styled.div`
  display: grid;
  border-radius: 24px;
  padding-top: 20px;
  border-top: 2px solid #F7F9FE;
  display: grid;
  gap: 10px 14px;
  grid-template-columns: ${(props)=>{
    let result = '';
    for(let i=0; i<props.column;i++) {
        result=result+ '1fr '
    }
    return result;
}} ;
  color:${(props)=>{
    return props.colorTxt ?? 'black';
}};

  @media (max-width: 768px) {
    max-width: 100%;
    overflow: scroll;
}
`;



const Cards= styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const Card = styled.div`
  cursor: pointer;
  width: 352px !important;
  height: 364px;
  border: 1px solid #E6E8EC;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px;
  margin:16px;
  margin-bottom: 128px;
  transition: all .5s ease-in-out;
  position: relative;
  background-color:${(props)=>{
    return props.bg ?? '#F7F9FE';
  }} ;
  color:${(props)=>{
    return props.colorTxt ?? 'black';
  }};
  .arrow{
    display: none;
    position: absolute;
    bottom: 32px;
    right: 32px;
  }
  &:hover {
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    .arrow{
      display: block;
    }
    
  }

  @media (max-width: 768px) {
    display: none;
  }
  
`

const CardTitle= styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 16px;
  padding-top: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const CardSubtitle= styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const ImgWrapper= styled.div`
  width: 100%;
`

const Img= styled.img`
  width: 64px;
  padding-top: 32px;
`

const CardsMobile= styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const TitleMain=styled.div`
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #141416;
  padding-bottom: 8px;
`
const SubTitleMain=styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #353945;
  padding: 8px 0  40px 0;
`

const AdvantagesBlock = ({parameters})=>{

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const RectangleType=()=>{
        if(!!parameters.is_table){
            return <TableWrapper column={parameters.columns?parameters.columns:3} style={{alignItems:'end',borderRadius:0}}>
                {parameters.items.sort((a,b) => {return a.order_id - b.order_id}).map((el,index)=>{
                return <InfoBlock style={{alignItems:'flex-start'}} key={Math.random()+index}
                                  onClick={()=> {if(el.link) return  window.open(el.link)}}>
                    {el.image?<Icon src={el.image}/>:null}
                    <Title style={{ 'text-transform':'none', textAlign:'center',padding:0}}>
                        {el.link?<span style={{textDecoration:'underline',color:'#3183DD',cursor:'pointer'}}>{el.title}</span>:<span>{el.title}</span>}</Title>
                    <SubTitle style={{textAlign:'start'}} dangerouslySetInnerHTML={{__html: replaceWithBr(el.description)}}/>
                </InfoBlock>
            })}</TableWrapper>
        }else{
            return <RectangleWrapper bg={parameters.background_color} colorTxt={parameters.text_color} column={parameters.columns?parameters.columns:3}>
                {parameters.items.sort((a,b) => {return a.order_id - b.order_id}).map((el,index)=>{
                    return <InfoBlock key={Math.random()+index}
                                      onClick={()=> {if(el.link) return  window.open(el.link)}}>
                        {el.image?<Icon src={el.image}/>:null}
                        <Title>{el.title}</Title>
                        <SubTitle dangerouslySetInnerHTML={{__html: replaceWithBr(el.description)}}/>
                    </InfoBlock>
                })}
            </RectangleWrapper>
        }


    }
    const LineType=()=>{

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
        };

        const settingsMobile={
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        }
        return <div>
            <Cards>
                <Slider {...settings}>
                    {parameters.items?.map((e,i)=>{
                        return(
                            <Card key={i+Math.random()}
                                  onClick={()=> {
                                      if(e.link) return window.open(e.link, "_self");
                                  }}  bg={parameters.bg_color} colorTxt={parameters.text_color}>
                                <ImgWrapper>
                                    {e.image?<Img src={e.image}/>:null}
                                </ImgWrapper>
                                <CardTitle>{e.title}</CardTitle>
                                <CardSubtitle  dangerouslySetInnerHTML={{__html: replaceWithBr(e.description)}}/>
                            </Card>
                        )
                    })}
                </Slider>
            </Cards>

            <CardsMobile>
                <Slider {...settingsMobile}>
                    {parameters.items?.map((e,i)=>{
                        return(
                            <Card key={i+Math.random()}  bg={parameters.bg_color} colorTxt={parameters.text_color} >
                                <ImgWrapper>
                                    {e.image?<Img src={e.image}/>:null}
                                </ImgWrapper>
                                <CardTitle>{e.title}</CardTitle>
                                <CardSubtitle  dangerouslySetInnerHTML={{__html: replaceWithBr(e.description)}}/>
                            </Card>
                        )
                    })}
                </Slider>
            </CardsMobile>
        </div>
    }

    return(
        <Wrapper  data-aos='fade-up' data-aos-duration="500">
            <Content>
                {parameters.title?<TitleMain>{parameters.title}</TitleMain>:null}
                {parameters.description?<SubTitleMain  dangerouslySetInnerHTML={{__html: replaceWithBr(parameters.description)}}/>:null}
                {parameters.type === 'rectangle'? <RectangleType/> :<LineType/>}
            </Content>
        </Wrapper>
    )
}

export default AdvantagesBlock
