import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  padding: 14px;
  border-bottom: 1px solid #E6E8EC;
  border-top: 1px solid #E6E8EC;
`;

const Links = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
`;

const LinkInactive = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #777E91;
  padding-right: 9px;
  cursor: pointer;
`;

const LinkActive = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding-left: 9px;
  color: #03153D;
`;

export const PathHeader = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    return(
        <Wrapper>
            <Links>
                {
                    props.paths.map((path, index) => {
                        if (index === props.paths.length - 1) {
                            return (
                                <LinkActive key="selected">{path}</LinkActive>
                            )
                        } else {
                            return (
                                <>
                                    <LinkInactive key={'option' + index} onClick={()=> {
                                        if(path===t('Main')) return history.push("/");
                                        if(path===t('News')) return history.push("/news");
                                        else return history.back();
                                    }}>{path}</LinkInactive> >
                                </>
                            )
                        }
                    })
                }
            </Links>
        </Wrapper>
    )
}
