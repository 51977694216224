import React, {useEffect, useState} from 'react';
import Layout from "../components/Shared/Layout";
import {useParams} from "react-router-dom";

import { YMaps,Map, Placemark } from "react-yandex-maps";
import {ContentHeader, PathHeader} from "../components/PageContentWidgets";
import styled from "styled-components";
import {dataService} from "../services/getContent.service";
import {Icons} from "../Icons/Icons";
import imgPlaceMarket from '../img/placePoint.png'
import {replaceWithBr} from "../components/Shared/sharedFunction";
import {ButtonStyle} from "../components/UI/CommonClasses";
import {useTranslation} from "react-i18next";


const Content2 = styled.div`
  width: 1120px;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  padding-top: 36px;
  
  border-bottom: 1px solid #E6E8EC;
  padding-bottom: 20px;
  
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    padding: 0 24px 0 24px;
    height: unset;
    margin-bottom: 20px;
  }
`;

const Wrapper = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  
  &.desktop{
    @media (max-width: 768px) {
      display: none !important;
    }
  }

  @media (max-width: 768px) {
    &.mobile{
      padding: 8px;
    }
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const SelectTitle = styled.div`
    margin-top: 32px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
    border: 2px solid #E6E8EC;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #03153D;
  min-width: 232px;
  
  cursor: pointer;

`

const Item = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #777E91;
  padding-right: 40px;
  cursor: pointer;
  padding: 16px;
  
  &.active{
    
    color: #03153D;
    border-bottom: 2px solid #0029D3;
  }
`

const Select = styled.div`
    margin-bottom: 20px;
  min-width: 134px;
  margin-right: 24px;
  position: relative;

`
const SelectOptions = styled.div`
    background: #FCFCFD;
    border: 2px solid #E6E8EC;
    box-sizing: border-box;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 12px;
  position: absolute;
  width: -webkit-fill-available;
  z-index: 1000;
  
`
const Option = styled.div`
    //width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #03153D;
  cursor: pointer;
`

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.desktop{
    @media (max-width: 768px) {
      display: none !important;
    }
  }
  @media (max-width: 768px) {
    //display: none;
    flex-direction: column;
  }
  
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
`
const MapItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 16px;
  background: #FFFFFF;

  border: 1px solid #E6E8EC;
  border-radius: 16px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    padding: 24px;
  }
`

const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #141416;
  padding-bottom: 20px;
`


const Location = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #777E91;
  border-bottom: 1px solid #E6E8EC;
  padding-bottom: 16px;
  margin-bottom: 16px;
`

const Time = styled.div`
    display: flex;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #E6E8EC;

  /* Neutrals/4 */

  color: #777E91;
  margin-bottom: 16px;
  padding-bottom: 8px;
  span{
    padding-right: 16px;
  }
`

const Info = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: flex-start;

  color: #777E91;
  padding-bottom: 16px;
  span{
    padding-right: 16px;
  }
`

const MapModule =styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 16px;
  background: #FFFFFF;
  opacity: 0.96;

  border: 1px solid #E6E8EC;
  border-radius: 16px;
  margin-bottom: 24px;
  height: max-content;
  height: 600px;
  width: 352px;
  @media (max-width: 768px) {
    padding: 24px;
    width: unset;
  }
`
const CloseBtn = styled.span`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  align-self: center;
  padding: 8px;
  font-size: 14px;
  box-sizing: border-box;
  position: absolute;
  right: 21px;
  top: 21px;
`

const MobileFilter= styled.div`
    display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

const CloseFilterBtn = styled.div`
    display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 20px;
  color: #03153D;
  
  border-bottom: 1px solid #E6E8EC;
  
`

const Maps = () => {
    const [filter, setFilter]=useState({
        pagination:1000,
    });
    const [openFilter, setOpenFilter]=useState(false);
    const [mapData, setMapData]=useState([]);
    const [moduleData, setModuleData]=useState("");
    const {id} =useParams();
    const [mobileFilter,setMobileFilter]=useState(false)
    const { t } = useTranslation();

    const types=[
        {
            id:1,
            text: t('On the map')
        }, {
            id:2,
            text: t('Listed by')
        },
    ];

    const region =[
        {id: 1, title: "Bishkek"},
        {id: 2, title: "Chui oblast"},
        {id: 3, title: "Issyk-Kul oblast"},
        {id: 4, title: "Naryn oblast"},
        {id: 5, title: "Batken oblast"},
        {id: 6, title: "Osh oblast"},
        {id: 7, title: "Talas oblast"},
        {id: 8, title: "Dzhalal-Abad oblast"},
        {id: 9, title: "Osh"},
        {id: 10, title: "TSUM branch"}
    ]

    const filterType={
        atms:[
            // {name:'region_id',text:'Регион',type:'select', options:region}
            {name: 'currency_type',text: t('ATM type'),type: 'select', options: [{id:'cash',title: t('All')},{id: 'cash_in',title:'Cash-in'},{id: 'cash_out',title:'Cash-out'}]},
            {name:'currency_id',text: t('Currency'),type:'select', options:[{id:1,title:'KGS'},{id:2,title:'USD'}]},
            // {name:'cash_in', text:'Валюта приёма денег', type: 'select',options: currency},
            // {name:'cash_out', text:'Валюта выдачи денег', type: 'select',options: currency},
            {name:'is_work24',text: t('Round the clock'),type:'toggle'},
        ],
        offices:[
            {name:'region_id',text: t('Region'),type:'select', options:region},
            // {name:'branches',text:'Отделения',type:'select', options:[]}
        ]
    };


    const selectTitle=(item)=>{
        if(!filter[item.name]) return t('All');
        else{
            if(item.name === 'region_id'){
                return t(region[filter[item.name]-1].title);
            } else {
               return  filterType[id].filter(el=>el.name===item.name)[0].options.filter(el=> {
                   return  el.id === filter[item.name][0]
               })[0].title
            }
        }


    }


// useEffect(()=>{
//     dataService.getCurrency().then((data)=>{
//         setCurrency(data.data);
//     })
// },[])

    useEffect(() => {
        dataService.getMaps(id,{filter}).then(data => {
            if(filter.currency_type && filter.currency_id){
                setMapData(
                 data.data.filter((item,index)=>{
                     if(filter.currency_type[0]==='cash') return true;
                     if(!!item[filter.currency_type[0]].length){
                         for(let i=0;i<item[filter.currency_type[0]].length;i++){
                                 if(filter?.currency_id[0]===item[filter.currency_type[0]][i].id) {
                                     return true
                                 }
                         }
                         return false;
                     }else{
                         return false;
                     }
                 })
                )
            }
            else if(filter.currency_type){
                setMapData(
                    data.data.filter((item,index)=>{
                        if(filter.currency_type[0]==='cash') return true;
                        return !!item[filter.currency_type[0]].length
                    })
                )
            }
            else{
                setMapData(data.data)
            }
            }
        );

    }, [filter])
    const [openMenu, setOpenMenu]=useState(1)

    return(
        <Layout>
            <PathHeader paths={[t('Main'), id==='atms'? t('ATMs') : t('Offices')]}/>
            <Wrapper style={{paddingTop:'64px'}}>
                <ContentHeader title={id==='atms'? t('ATMs') : t('Offices')}/>
                <Content2>
                    {types.map(el=>
                        <Item
                            className={el.id===openMenu?'active':''} onClick={()=>setOpenMenu(el.id)}
                        >{el.text}</Item>
                    )}
                </Content2>
                <Filter className={"desktop"}>
                    {filterType[id].map(item=> item.type==='select'?<Select>
                        <SelectTitle onClick={()=>setOpenFilter(item.name===openFilter?'':item.name)}>
                            <span>

                                {item.text}: {selectTitle(item)}

                            </span>
                            <span>{Icons.arrowBottom}</span>
                        </SelectTitle>
                        {openFilter === item.name ? <SelectOptions>
                            { item?.options?.map((el, index)=>(
                                <Option key={Math.random()+index} onClick={()=> {
                                setFilter({...filter,[item.name]:[el.id?el.id:el]})
                                setOpenFilter('')
                            }}>
                                {
                                    el.title ? t(el.title) : t(el)
                                }
                            </Option>
                            ))}
                        </SelectOptions>:null}
                    </Select>:<div style={{display:'flex',alignItems:'center'}}>
                        <input type='checkbox' style={{width:'24px',height:'24px'}} onClick={()=>{
                            setFilter({...filter,[item.name]:filter[item.name]?false:true})
                        }
                        }/>
                        {item.text}
                    </div>)}
                </Filter>
                <MobileFilter>
                    <ButtonStyle color={'#03153D'} style={{marginBottom:'20px'}} onClick={()=>setMobileFilter(true)}>Фильтр <span style={{paddingLeft:'14px', alignItems:"center",display:'flex'}}>{Icons.filter}</span> </ButtonStyle>
                    {mobileFilter?  <div><Filter style={{width:'100%', alignItems:'flex-start', marginTop:'20px'}} >
                        {filterType[id].map(item=> item.type==='select'?<Select style={{width:'100%',margin:'0px'}} >
                            <SelectTitle style={{width:'100%', margin:'16px 0 0 0'}} onClick={()=>setOpenFilter(item.name===openFilter?'':item.name)}>
                            <span>

                                {item.text}: {selectTitle(item)}

                            </span>
                                <span>{Icons.arrowBottom}</span></SelectTitle>
                            {openFilter === item.name? <SelectOptions>
                                { item?.options?.map((el,index)=>(<Option key={Math.random()+index} onClick={()=> {
                                    setFilter({...filter,[item.name]:[el.id?el.id:el]})
                                    setOpenFilter('')
                                }}>
                                    {
                                        el.title ? t(el.title) : t(el)
                                    }
                                </Option>
                                )) }
                            </SelectOptions>:null}
                        </Select>:<div style={{display:'flex',alignItems:'center',margin:'16px 0 0 0'}}>
                            <input type='checkbox' style={{width:'24px',height:'24px'}} onClick={()=>{
                                setFilter({...filter,[item.name]:filter[item.name]?false:true})
                            }
                            }/>
                            {item.text}
                        </div>)}
                    </Filter>
                        <CloseFilterBtn onClick={()=>setMobileFilter(false)}>{t('Hide filter')}</CloseFilterBtn></div>:null}
                </MobileFilter>
            </Wrapper>
            {openMenu===1?  <YMaps  style={{width:"100%",position:'relative'}}>
                <Wrapper style={{position:'absolute',zIndex:'100000',left:0,right:0}} className={'mobile'}>
                    <MapModule style={{display:moduleData?'block':"none"}}>
                        <CloseBtn onClick={()=>setModuleData('')}>&#x2715;</CloseBtn>
                        {
                        id==='atms'?
                            <>
                                <Title>{moduleData.title}</Title>
                                <Location  dangerouslySetInnerHTML={{__html: replaceWithBr(moduleData.address)}}/>
                                <Time><span> {Icons.time}</span>{t('Working hours')}: {moduleData.working_hours}</Time>
                                <Info>
                                    <span>{Icons.atms}</span>
                                    <div>
                                        <div style={{paddingBottom:'16px'}}>{t('Currency of money acceptance')} <br/>(Cash-in): <b>
                                            {moduleData?.cash_in?.map(e=>e.title+' ')}</b></div>
                                        <div>{t('Currency of money disbursement')}<br/>(Cash-out): <b> {moduleData?.cash_out?.map(e=>e.title+' ')}</b></div>
                                        {moduleData['number']?<div style={{paddingTop:'16px'}}>{t('ATM number')} : № {moduleData['number']}</div>:null}
                                    </div>
                                    </Info>
                            </>:
                            <>
                                <Title>{moduleData.title}</Title>
                                <Location>{moduleData.address}</Location>
                                <Info>
                                    <span>{Icons.phone}</span>
                                    <div>
                                        <div>{t('Main phone')}: {moduleData.phone}<br/>
                                            {moduleData.phone_additional}
                                        </div>
                                    </div>
                                </Info>
                                <Info>
                                    <span>{Icons.fax}</span>
                                    <div>
                                        <div>{t('Fax')}: {moduleData.fax}<br/>
                                            {moduleData.fax_additional}
                                        </div>
                                    </div>
                                </Info>
                                <Time>
                                    <span> {Icons.time}</span>
                                    <div style={{display:'flex',flexDirection:'column'}}>
                                        <span dangerouslySetInnerHTML={{__html: replaceWithBr(t('Working hours') + ': '+moduleData.working_hours)}}/>
                                        <span dangerouslySetInnerHTML={{__html: replaceWithBr(`(${t('break')}: `+moduleData.break+')')}}/><br/>
                                        {t('Working days')}: {moduleData.work_days}
                                    </div>
                                </Time>
                            </>
                    }</MapModule>
                </Wrapper>
                <Map defaultState={{ center: [42.873159620957786, 74.61561214418033], zoom: 9 }}  style={{width:"100%",height:'680px'}}>
                    {mapData.map(item=>{
                       return <Placemark geometry={[Number(item.latitude), Number(item.longitude)]}
                                         options={{
                                             iconLayout: 'default#image',
                                             iconImageHref: imgPlaceMarket,
                                             iconImageSize: [30, 42],
                                             iconImageOffset: [-3, -42]
                                         }}
                                         onClick={()=> {
                                             setModuleData(item)
                                         }}
                       />
                    })}
                </Map>
            </YMaps>:<Wrapper>
                <List>
                    {mapData.map(item=>
                        id==='atms'?
                        <MapItem>
                        <Title>{item.title}</Title>
                        <Location  dangerouslySetInnerHTML={{__html: replaceWithBr(item.address)}}/>
                        <Time><span> {Icons.time}</span>{t('Working hours')}: &nbsp; <b> {item.working_hours}</b></Time>
                        <Info>
                            <span>{Icons.atms}</span>
                            <div>
                                <div style={{paddingBottom:'16px'}}>{t('Currency of money acceptance')}  <br/>(Cash-in): <b>
                                    {item?.cash_in?.map(e=>e.title+' ')}</b></div>
                                <div>{t('Currency of money disbursement')}  <br/>(Cash-out): <b>
                                    {item?.cash_out?.map(e=>e.title+' ')}</b></div>
                                {item['number']?<div style={{paddingTop:'16px'}}>{t('ATM number')} : № {item['number']}</div>:null}
                            </div>
                            </Info>
                        </MapItem>:
                            <MapItem>
                                <Title dangerouslySetInnerHTML={{__html: replaceWithBr(item.title)}}/>
                                <Location dangerouslySetInnerHTML={{__html: replaceWithBr(item.address)}}/>
                                <Info>
                                    <span>{Icons.phone}</span>
                                    <div>
                                        <div>{t('Main phone')}: {item.phone}<br/>
                                            <div  dangerouslySetInnerHTML={{__html: replaceWithBr(item.phone_additional)}}/>
                                        </div>
                                    </div>
                                    </Info>
                                <Info>
                                    <span>{Icons.fax}</span>
                                    <div>
                                        <div>{t('Fax')}: {item.fax}<br/>
                                            <div  dangerouslySetInnerHTML={{__html: replaceWithBr(item.fax_additional)}}/>
                                        </div>
                                    </div>
                                </Info>
                                <Time>
                                    <span> {Icons.time}</span>
                                    <div style={{display:'flex',flexDirection:'column'}}>
                                        <span dangerouslySetInnerHTML={{__html: replaceWithBr(t('Working hours') + ': '+item.working_hours)}}/>
                                        <span dangerouslySetInnerHTML={{__html: replaceWithBr(`(${t('break')}: `+item.break+')')}}/><br/>
                                        {t('Working days')}: {item.work_days}
                                    </div>
                                </Time>
                            </MapItem>
                    )
                    }
                </List>
            </Wrapper>
            }
        </Layout>)
}
export default Maps;
