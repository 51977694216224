import React, { createContext, useState } from 'react';


export const NewsContentContext = createContext(null);

export const NewsContentProvider = ({ children }) => {
    const [news, setNews] = useState(null);

    const setNewsContent = (newsContent) => {
        setNews(newsContent)
    }

    return (
        <NewsContentContext.Provider
            value={{
                news,
                setNewsContent,
            }}
        >
            {children}
        </NewsContentContext.Provider>
    );
};
