import React, {useEffect, useState} from 'react';
import AOS from "aos";
import styled from "styled-components";
import {Button} from "../UI/CommonClasses";
import useModule from "../../hooks/useModule";
import {useTranslation} from "react-i18next";
import successLogo from "../../img/success_icon.png"
import errorLogo from "../../img/error_icon.png"


const Wrapper = styled.div`
  z-index: 100000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 130px;
  position: fixed;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #00000059;
`;

const Content = styled.div`
  width: 448px;
  display: flex;
  color: #FCFCFD;
  flex-direction: column;
  background: #F7F9FE;
  border-radius: 24px;
  padding: 32px;
  
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin: 32px 32px 32px 32px;
    padding: 32px 24px 32px 24px;
    height: unset;
  }
`;

const Title = styled.div`
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;

  letter-spacing: -0.02em;
  color: #03153D;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
  }
`
const CloseBtn = styled.span`
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #E6E8EC;
  width: 32px;
  height: 32px;
  align-self: center;
  padding: 8px;
  font-size: 14px;
  box-sizing: border-box;
  margin-left: auto;
`

const Subtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #353945;
  padding-bottom: 10px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
  }

`;

const Img = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 40px;
`;

const FeedbackSentWindow = (props)=>{
    const { t } = useTranslation();

    const {isModuleOpen, setModule} = useModule();
    const [success, setSuccess] = useState(props.success);
    const [error, setError] = useState(props.error);
    useEffect(() => {
        AOS.init({duration: 1500});
        AOS.refresh();
    }, []);

    return(
        <Wrapper data-aos='zoom-out' data-aos-duration="500">
            <Content>
                <Title>
                    {!success && !error && <span>{isModuleOpen.text}</span>}
                    <CloseBtn onClick={()=>setModule({open:false})}>&#x2715;</CloseBtn>
                </Title>
                {success && <>
                    <Img src={successLogo}/>
                    <Title>
                        Спасибо за обратную связь! Ваш отзыв принят в работу.
                    </Title>
                    <Subtitle>
                        <span>Спасибо, что делитесь обратной связью!</span>
                    </Subtitle>
                    </>
                }
                {error && <>
                    <Img src={errorLogo}/>
                    <Title>
                        {t('Some error happened!')}
                    </Title>
                    <Subtitle>
                        <span>{t(`We're sorry, seems like something went wrong. Please, call to contact center or try again.`)}</span>
                    </Subtitle>
                    </>
                }
                {(success || error) && <>
                    <div style={{margin:'10px 0' , width:'100%'}}/>
                    <Button onClick={()=>setModule({open:false})}>OK</Button>
                </>}
            </Content>
        </Wrapper>
    )
}

export default FeedbackSentWindow
