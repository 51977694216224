import React, {useEffect} from 'react';
import styled from 'styled-components';
import {keyframes} from 'styled-components'
import logo from '../../img/logo.png'
import useLoading from "../../hooks/useLoading";
import useWindowSize from '../../hooks/useWindowSize'
import {BurgerMenu} from '../UI/CommonClasses'
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80px;
  z-index: 100000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.72);
  @media (max-width: 768px) {
    backdrop-filter: blur(4px);
  }
`;

const Content = styled.div`
  width: 1120px;
  height: 80px;
  display: flex;
  flex-direction: row;
  padding: 0 28px;
  justify-content: space-between;


  @media (max-width: 768px) {
    width: 100%;
    position: relative;
  }
  
`;

const Menu = styled.div`
    display: flex;
  flex-direction: row;
`;

const RightContent = styled.div`
    display: flex;
  flex-direction: row;
  align-items: center;


  @media (max-width: 768px) {
    padding-left: 32px;
  }
  
`;
const LeftContent = styled.div`
display: flex;
  flex-direction: row;
  align-items: center;
  
`;

const Item = styled.div`
  margin-right: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: ${props=>{
      if(props.active)
          return'#0068FF';
    else return '#23262F';
  }};
  cursor: pointer;
`;

const Line = styled.div`
    height: 40px;
  border-right: 2px solid #E6E8EC;
  border-radius: 2px;
  margin: 0 16px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;


const ProductSkeleton = styled.div`
  display: inline-block;
  height: ${props => props.height || "14px"};
  width: ${props => props.width || "80%"};
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(
    90deg,
    #eee,
    #f5f5f5,
    #eee
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: ${props => props.marginTop || "0"}
`;

const Logo = styled.div`
  height: 25px;
  cursor: pointer;

  .logo{
    height: 25px;
  }
`;

const Button = styled.div`
  margin-right: 12px;
  background: #0068FF;
  border-radius: 8px;
  padding: 12px 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FCFCFD;
  cursor: pointer;

    `

;

const LangSelect = styled.select`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #23262F;
  height: 40px;
  margin-left: 8px;
  align-self: center;
  border-radius: 8px;
  border: 2px solid #E6E8EC;
  @media (max-width: 768px) {
    margin-right: 20%;
  }
`;


const Header = (props)=>{
    const {isLoading} = useLoading();
    const {isDesktop}= useWindowSize();
    const history = useHistory();
    const currentLang = localStorage.getItem('currentLang') || 'ru';
    const { i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(currentLang);
    }, []);
    const handleLangChange = (input) => {
        localStorage.setItem('currentLang', input.target.value);
        window.location.reload(true);
    };

    const chooseMenu=(id)=>{
        if(id===props.part){
            props.setPart(null)
            props.setOpenMenu(false)
        }
        else{
            props.setPart(id)
            props.setOpenMenu(true)
        }
    }


    return(
        <Wrapper>
            <Content>
                <RightContent>
                    <Logo onClick={()=>history.push("/")}>
                    <img className={'logo'} src={logo} alt="Kyrgyzkommertsbank"/>
                    </Logo>
                    <Line/>
                    {isDesktop?
                        <Menu>
                            {isLoading ?
                                props?.menu?.map((item,index)=>  <Item onClick={()=> {
                                    if(item.subs.length===0 && item.link){
                                        return window.open(item.link, "_self")
                                    }else chooseMenu(item.id)
                                }} key={index+Math.random()} active={props.part===item.id}>{item.title}</Item>):
                                [1,2,3].map((e, i) => <Item  key={i + Math.random()} ><ProductSkeleton width="150px" height="24px"
                                                                              marginTop="8px"/></Item>)}
                        </Menu>

                        :null}
                </RightContent>

                {isDesktop? <LeftContent>
                    {/*<Search>{Icons.search}</Search>*/}
                    {/*<Button onClick={()=>window.open('https://kkb24.kg/', "_self")}>Интернет-банкинг</Button>*/}
                    <Button onClick={()=>window.open('https://kkb.kg/page/140', "_self")}>
                        OnlineBank
                    </Button>
                    {/*<Button2 onClick={()=>window.open('https://videobank.kkb.kg/', "_self")}>
                        <IconsStyle> {Icons.video}</IconsStyle>
                        Видеобанкинг
                    </Button2>*/}

                </LeftContent>:<BurgerMenu onClick={()=> props.openMenu ? props.setOpenMenu(false) : props.setOpenMenu(true)} burgerOpen={props.openMenu}>
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                </BurgerMenu>}
                <LangSelect onChange={handleLangChange} defaultValue={currentLang}>
                    <option value="kg">Кыргызча</option>
                    <option value="ru">Русский</option>
                    <option value="en">English</option>
                </LangSelect>



            </Content>


            {/*{*/}
            {/*    [1, 2,3].map((e, i) => <ProductSkeleton key={i + Math.random()} width="150px" height="24px"*/}
            {/*                                            marginTop="8px"/>)}*/}
        </Wrapper>
    )
}

export default Header
