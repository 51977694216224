import React from 'react';

import { LoadingProvider } from './LoadingProvider';
import { ModuleProvider} from "./ModuleProviders";
import { NewsContentProvider } from "./NewsContentProvider";


export const ContextProviders = ({children}) => {
    return (
        <ModuleProvider>
            <LoadingProvider>
                <NewsContentProvider>
                    {children}
                </NewsContentProvider>
            </LoadingProvider>
        </ModuleProvider>
    );
};
