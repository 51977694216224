import React, {useEffect, useState} from 'react';
import Layout from "../components/Shared/Layout";
import {BreadCrumb} from "../components/BreadCrumb/BreadCrumb.js";
import styled from "styled-components";
import {dataService} from "../services/getConstructorContent.service";
import {Icons} from '../Icons/Icons';
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";

const CurrenciesWrapper = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding-top: 64px;
  padding-bottom: 128px;
  @media (max-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;


const CurrenciesHeader = styled.div`
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #141416;
  align-items: center;
  display: flex;
  @media (max-width: 768px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
  }
`;
const CurrenciesTypes = styled.div`
  display: flex;
  padding-top: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const CurrenciesType = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #777E91;
  margin-right: 12px;
  padding: 6px 12px;
  border-radius: 100px;
  cursor: pointer;
  &.active {
    color: #ffffff;
    background: #0029D3;
  }
`;
const CurrenciesTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #141416;
  padding-top: 40px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E6E8EC;
  @media (max-width: 768px) {
    padding-top: 20px;
  }
  
`;
const CurrenciesText = styled.div`
  padding: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #777E91;
  background: #F7F9FE;
  border-radius: 24px;
  margin-top: 40px;
`;
const CurrenciesLinks = styled.div`
  padding: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #777E91;
  background: #F7F9FE;
  border-radius: 24px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;
const CurrenciesLink = styled.a`
  color: #282c34;
  text-decoration: none;
  cursor: pointer;
`;
const CurrenciesTable = styled.table`
  margin-top: 24px;
  width: 100%;
  th {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #777E91;
    text-align: left;
    padding-bottom: 28px;
    &:last-child {
      text-align: right;
    }
  }
  td {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #353945;
    text-align: left;
    padding-bottom: 28px;

    &:last-child {
      text-align: right;
    }
  }
`;

const Country = styled.div`
    display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  min-width: 68px;
  justify-content: space-between;
  @media (max-width: 768px) {
    min-width: 30px;
  }
`
const Line = styled.div`
  height: 20px;
  width: 1px;
  border-left: 1px solid #E6E8EC;
  padding-right:16px;
  margin-left: 16px;
`


const SelectMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-bottom: 20px;
    .active{
      color: #0068FF;
    }
  }
`
const SelectOptions = styled.div`
  @media (max-width: 768px) {
    background: #FCFCFD;
    border: 2px solid #E6E8EC;
    box-sizing: border-box;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 12px;
  }
`
const Option = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #03153D;
  }
`

const MenuTitle = styled.div`
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;

  letter-spacing: -0.01em;

  color: #03153D;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    
    margin-top: 32px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 16px;
    border: 2px solid #E6E8EC;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #03153D;
  }
`

const Content2 = styled.div`
  width: 1120px;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  padding-top: 36px;
  
  border-bottom: 1px solid #E6E8EC;
  
  @media (max-width: 768px) {
    width: unset;
    flex-direction: row;
    padding: 0 24px 0 24px;
    height: unset;
    margin-bottom: 20px;
  }
`;

const Item = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #777E91;
  padding-right: 40px;
  cursor: pointer;
  padding: 16px;

  @media (max-width: 768px) {
    white-space: nowrap;
  }
  
  &.active{
    
    color: #03153D;
    border-bottom: 2px solid #0029D3;
  }
`
const Currency = () => {
    const [currencyData, setCurrencyData]= useState([])
    const [openSelect,setOpenSelect]=useState(false);
    const [filter, setFilter]= useState('cash');
    const history = useHistory();
    const [openMenu, setOpenMenu]=useState(1);
    const [goldData,setGoldData]=useState([]);
    const { t } = useTranslation();
    const types=[
        {
            id:1,
            text: t('Exchange rates')
        }, {
            id:2,
            text: t('Gold rates')
        },
    ];


    const filterType=[
        {name: t('Cash'),value:'cash'},
        {name: t('Non-cash'),value:'cashless'},
        {name: t('Payment cards'),value:'card'},
        {name: t('NBKR rates'),value:'nb'},
    ];

    useEffect(()=>{
        dataService.getCurrency().then(data => {
            setCurrencyData(data.data);
        })
        dataService.getGold().then(data=>{
            setGoldData(data.data)
        })
    },[])

    const dateCreate=(date)=>{
        if (!date){
            return ;
        }
        const year = date.slice(0,4)
        const day=date.slice(8,10)
        const month=date.slice(5,7)
        return day+'.'+month+'.'+year
    }

    return <Layout>
        <div style={{paddingTop:'80px'}}>
            <BreadCrumb></BreadCrumb>
            <CurrenciesWrapper>
                <CurrenciesHeader>
                    <div style={{paddingRight:'24px', display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=>history.push("/")}> {Icons.ArrowLeft}</div>
                    {t('Exchange rates')}
                </CurrenciesHeader>
                <Content2>
                    {types.map(el=>
                        <Item
                            className={el.id===openMenu?'active':''} onClick={()=>setOpenMenu(el.id)}
                        >{el.text}</Item>
                    )}
                </Content2>
                {openMenu===1?<>
                    <SelectMobile>
                        <MenuTitle onClick={()=>setOpenSelect(true)}>
                            <span>{filterType.filter(item=>item.value===filter)[0].name}</span>

                            <span>{Icons.arrowBottom}</span></MenuTitle>
                        {openSelect? <SelectOptions>
                            { filterType.map((item,index)=>(<Option onClick={()=> {
                                setFilter(item.value);
                                setOpenSelect(false)
                            }}
                            className={filter===item?.value?'active':''}
                            >{item.name}</Option> )) }
                        </SelectOptions>:null}
                    </SelectMobile>
                    <CurrenciesTypes>
                        <CurrenciesType className={filter==='cash'?'active':''}  onClick={()=>setFilter('cash')}>
                            {t('Cash')}
                        </CurrenciesType>
                        <CurrenciesType className={filter==='cashless'?'active':''} onClick={()=>setFilter('cashless')}>
                            {t('Non-cash')}
                        </CurrenciesType>
                        <CurrenciesType className={filter==='card'?'active':''}  onClick={()=>setFilter('card')}>
                            {t('Payment cards')}
                        </CurrenciesType>
                        <CurrenciesType className={filter==='nb'?'active':''} onClick={()=>setFilter('nb')}>
                            {t('NBKR rates')}
                        </CurrenciesType>
                    </CurrenciesTypes>
                    <CurrenciesTitle>
                        Курсы конвертации по наличной валюте на {dateCreate(currencyData?.filter(e=>e.type===filter)[0]?.updated_at.toString().slice(0,10))}
                    </CurrenciesTitle>
                    <CurrenciesTable>
                        <thead>
                        <tr>
                            <th>{t('Currency pairs')}</th>
                            <th>{filter==='nb' ? '' : t('Buy')}</th>
                            <th>{filter==='nb' ? '' : t('Sell')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currencyData?.filter(e=>e.type===filter).map(item=>{

                            return( <tr>
                                <td style={{display:'flex'}}>
                                    <Country>{Icons[item.to]}{item.to}</Country>
                                    <Line/>
                                    <Country>{Icons[item.from]}{item.from}</Country>

                                </td>
                                <td>
                                    {isNaN(item.buy) || filter==='nb' ? item.buy : (Math.round(item.buy * 10000) / 10000).toFixed(4)}
                                </td>
                                <td>
                                    {isNaN(item.sell) || filter==='nb' ? item.sell : (Math.round(item.sell * 10000) / 10000).toFixed(4)}
                                </td>
                            </tr>)
                        })}

                        </tbody>
                    </CurrenciesTable>
                </>:<>
                    <CurrenciesTitle>
                        {t('Gold rates')} {dateCreate(goldData[0]?.updated_at.toString().slice(0,10))}
                    </CurrenciesTitle>
                    <CurrenciesTable>
                        <thead>
                        <tr>
                            <th>{t('Value (g)')}</th>
                            <th>{t('Buy')} (KGS)</th>
                            <th>{t('Sell')} (KGS)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {goldData?.map(item=>{

                            return( <tr>
                                <td style={{display:'flex'}}>
                                    {item.amount} {t('g.')}
                                </td>
                                <td>
                                    {(Math.round(item.buy * 10000) / 10000).toFixed(4)}
                                 </td>
                                <td>
                                    {item.sell}
                                </td>
                            </tr>)
                        })}

                        </tbody>
                    </CurrenciesTable>
                </>}

                <CurrenciesText>
                    {t('currenciesText')}
                </CurrenciesText>

                <CurrenciesLinks>
                    <CurrenciesLink href="/Minimum_requirements.PDF" target="_blank">
                        {t('complaintsText')}
                    </CurrenciesLink>
                    <CurrenciesLink href="/contact-data.pdf" target="_blank">
                        {t('contactsText')}
                    </CurrenciesLink>
                </CurrenciesLinks>
            </CurrenciesWrapper>
        </div>
    </Layout>

}

export default Currency;
