import styled from 'styled-components';
import useModule from "../../hooks/useModule";
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  height: 100%;
  background-size: cover;

  ${props=> {
    if(props.back){
      return `background-image: url(${props.back});
        @media (max-width: 768px) {
        border-radius:16px
};`
    }
  }};

  .mobile_cover{
    max-width: 360px;
    width: 340px;
    background: #E4F7EF ;
    border-radius: 16px;
    background: #E4F7EF;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .cover_pg{
    display: flex !important;
    justify-content: left !important;
    margin: 0 auto;
    margin-top: 40px;
  }
`;

export const Content = styled.div`
  width: 1120px;
  max-width: 1120px;
  justify-content: space-between;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;


  ${props=> {
    const result = 'display: flex; flex-direction:'
    if(!props.direction){
      return result+`column`
    }
    else{
      return result+props.direction;
    }
  }};



  @media (max-width: 768px) {
    width: 100%;
  }



`;

export const ButtonStyle = styled.button`
  justify-content: center;
  cursor: pointer;
  padding: 16px 24px;
  border-radius: 8px;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #FCFCFD;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  ${props=> {
    if(props.color){
      return `background: ${props.color};`
    }else{
      return 'background: #0068FF;'
    }
  }};
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
  }
`;


export const VectorImg = styled.div`
  padding-left: 15px;
  margin-top: -3px;
`;

export const BurgerMenu = styled.div`
  display: block;
  height: 21px;
  width: 27px;
  position: absolute;
  top: 33px;
  right: 37px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .line{
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #777E90;
  }
  .line1{
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;

  }
  .line2{
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  ${props=> {
    if(props.burgerOpen){
      return `   
      .line1 {transform: rotate(45deg);}
      .line2 {transform: rotate(-45deg);}`
    }
  }};

`


export const Button =({children, onClick, arrow=true,Modal,ModalText,color})=>{

  const {setModule} = useModule();
  return <ButtonStyle onClick={()=>Modal?setModule({open:true,text:ModalText}):onClick()} color={color}>
    {children}
    {
      arrow?<VectorImg>
        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M6.39388 0.843492C6.66443 0.593754 7.0862 0.610623 7.33594 0.881169L9.48978 3.21448C9.72552 3.46985 9.72552 3.86347 9.48979 4.11885L7.33594 6.45221C7.08621 6.72276 6.66443 6.73963 6.39389 6.4899C6.12334 6.24016 6.10646 5.81839 6.3562 5.54784L7.47727 4.33333L0.999918 4.33333C0.631728 4.33333 0.333251 4.03486 0.333251 3.66667C0.333251 3.29848 0.631728 3 0.999918 3L7.47725 3L6.3562 1.78555C6.10647 1.515 6.12334 1.09323 6.39388 0.843492Z" fill="#FCFCFD"/>
        </svg>
      </VectorImg>:null
    }
  </ButtonStyle>
}