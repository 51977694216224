import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Header from './Header'
import Footer from './Footer'
import Menu from '../Module/Menu'
import {dataService} from '../../services/getContent.service'
import useLoading from "../../hooks/useLoading";
import useWindowSize from "../../hooks/useWindowSize";
import AgreementWindow from "../Module/AgreementWindow";
import useModule from "../../hooks/useModule";
import whatsapp from '../../img/whatsapp_link.png';


const WhatsappLink = styled.a`
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  z-index: 100000;
`;

const WhatsappImg = styled.img`
  width: 60px;
  height: 60px;
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const Layout = (props)=>{
    const {setLoading} = useLoading();
    const [menu, setMenu]=useState([]);
    const [footerLink, setFooterLink]=useState([]);
    const [footerInfo, setFooterInfo]=useState([]);
    const [part, setPart]=useState(null)
    const [openMenu, setOpenMenu]= useState(false);
    const {isModuleOpen} = useModule();
    const {isDesktop}= useWindowSize();

    useEffect(() => {
        dataService.getParts().then(data => {
            setMenu(data.data.menu);
            setFooterLink(data.data['footer-links'])
            setFooterInfo(data.data.contacts)
            setLoading(true);
            if(!isDesktop) setPart(data.data.menu[0].id)
            }
        );
    }, [isDesktop])



    return(
        <div style={{ 'background': '#FFFFFF', 'overflowX':!isDesktop?'hidden':'hidden', 'overflowY':openMenu?'hidden':'hidden',paddingTop:'100px'}}>
            <Header setOpenMenu={setOpenMenu} menu={menu} openMenu={openMenu} setPart={setPart} part={part}></Header>
            {openMenu? <Menu setOpenMenu={setOpenMenu} menuContent={menu.filter(item=>item.id===part)} menu={menu} openMenu={openMenu} setPart={setPart} part={part}/>:null}
            {isModuleOpen.open?   <AgreementWindow/>:null}

            {props.children}
            {footerInfo.length && <WhatsappLink
                href={`http://api.whatsapp.com/send?phone=${footerInfo?.filter(el => el.type === "whatsapp")[0]?.content.match(/\d/g).join('')}`}
                target="_blank">
                <WhatsappImg src={whatsapp}></WhatsappImg>
            </WhatsappLink>
            }
            {footerLink.length? <Footer footerLink={footerLink} footerInfo={footerInfo}></Footer>:null}
        </div>
    )
}

export default Layout
