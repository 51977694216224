import React, { useEffect, useState } from "react";

import Layout from "../components/Shared/Layout";
import Cover from "../components/Cover/Cover";
import MobileBankBaner from "../components/MobileBankBaner/MobileBankBaner";
import PopularProducts from "../components/PopularProducts/PopularProducts";
import Banners from "../components/Banners/Banners";
import POSTerminalBaner from "../components/POSTerminalBaner/POSTerminalBaner";
import SpecialOffers from "../components/SpecialOffers/SpecialOffers";
import { dataService } from "../services/getContent.service";

const Main = () => {
  const [info, setInfo] = useState([]);

  useEffect(() => {
    dataService.getHome().then((data) => {
      setInfo(data.data);
    });
  }, []);

  return (
    <Layout>
      <Cover info={info?.banners} />
      <PopularProducts products={info?.products} />
      <MobileBankBaner
        mobile={
          info?.blocks?.filter((item) => item.type === "rectangle-top")[0]
        }
      />
      <Banners
        banners={info?.blocks?.filter((item) => item.type === "square")}
      />
      <SpecialOffers products={info?.offers} />
      <POSTerminalBaner
        terminal={
          info?.blocks?.filter((item) => item.type === "rectangle-bottom")[0]
        }
      />
    </Layout>
  );
};

export default Main;
