import "./App.css";
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { ContextProviders } from "./context/ContextProviders";
import Main from "./pages/Main";
import Constructor from "./pages/Constructor";
import Currency from "./pages/Currency";
import News from "./pages/News";
import Contacts from "./pages/Contacts";
import Maps from "./pages/Maps";
import NewsDetails from "./pages/NewsDetails";
import Rate from "./pages/Rate";

function App() {
  let routes = (
    <Router>
      <Route path="/" exact component={Main}></Route>
      <Route path="/page/:id" component={Constructor}></Route>
      <Route path="/currency" component={Currency}></Route>
      <Route path="/news/:newsIndex" exact component={NewsDetails}></Route>
      <Route path="/news" exact component={News}></Route>
      <Route path="/contacts" component={Contacts}></Route>
      <Route path="/maps/:id" component={Maps}></Route>
      <Route path="/rate" component={Rate}></Route>
    </Router>
  );

  return (
    <ContextProviders>
      <div
        style={{
          bg: "#F4F4F4",
          width: "100%",
          minHeight: "100vh",
          color: "#1E1E1E",
        }}
      >
        {routes}
      </div>
    </ContextProviders>
  );
}

export default App;
