import axios from 'axios';

const axiosApiInstance = axios.create();
const MAIN_URL = 'https://admin.kkb.kg/api/landings/'
const CURRENCY_URL = 'https://admin.kkb.kg/api/rates'

class DataService {

    async getContent(id) {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = `${MAIN_URL}${id}`;
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }
    async getCurrency() {
        const headers = {
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = `${CURRENCY_URL}`;
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async getGold() {
        const headers = {
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = `${CURRENCY_URL}/gold`;
        return axiosApiInstance.get(url,headers).then((response) => response.data);
    }

    async postRequest(data) {
        const headers = {
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = 'https://admin.kkb.kg/api/save-request';
        return axiosApiInstance.post(url, {...data},headers).then((response) => response.data);
    }


}

export const dataService = new DataService();
