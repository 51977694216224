import React,{ useState,useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Layout from "../components/Shared/Layout";
import { ContentWrapper, PathHeader, ContentHeader } from "../components/PageContentWidgets";
import styled from "styled-components";
import useWindowSize from "../hooks/useWindowSize";
import { dataService } from "../services/getContent.service";
import useNewsContent from "../hooks/useNewsContent";
import {dateConvertedLib} from "../shared/libs/"
import {Icons} from "../Icons/Icons";
import {useTranslation} from "react-i18next";

const SelectYearsWrapper = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  padding-bottom: 15px;
  margin-bottom: 40px;
  border-bottom: 1px solid #E6E8EC;
  @media (max-width: 768px) {
    //display: block;
    margin-bottom: 20px;
    .active{
      color: #0068FF;
    }
  }
`

const SelectYearWrapper = styled.div`
  position: relative;
  margin-right: 40px;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #ffffff;
    background: #0029D3;
  }
`

const SelectYearTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #777E91;
  cursor: pointer;
  //&.active {
  //  color: #ffffff;
  //  background: #0029D3;
  //}
`

const SelectYearIndicator = styled.div`
  position: absolute;
  bottom: -17px;
  left: 0px;
  height: 1px;
  width: 100%;
`

const NewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  //grid-template-rows: repeat(5, 1fr);
  //grid-template-rows: repeat(5, 1fr);
  row-gap: 32px;
  column-gap: 32px;
  width: 1120px;
  //height: 1788px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 32px;
    column-gap: 0px;
    width: unset;
    height: fit-content;
  }
`

const NewsBlock = styled.div`
  width: 352px;
  height: fit-content;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.25s ease;
  &:hover{
    transform: translate(0, -5px) scale(1.05);
  };
  @media (max-width: 768px) {
    width: 100%;
  }
`

const NewsBlockImage = styled.img`
  width: 352px;
  height: 220px;
  border-radius: 16px;
  @media (max-width: 768px) {
    width: 100%;
    object-fit: cover;
  }
`

const NewsBlockDate = styled.div`
  font-family: 'Inter';
  padding: 8px 0;
  width: 352px;
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #777E91;
  @media (max-width: 768px) {
    width: unset;
  }
`

const NewsBlockTitle = styled.div`
  font-family: 'Inter';
  width: 352px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #03153D;
  @media (max-width: 768px) {
    width: unset;
  }
`

const PageSelectionWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`
const PageSelectionButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 40px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  border: 2px solid #E6E8EC;
  cursor: pointer;
`

// const SelectedPage = styled.div`
//   width: 352px;
//   height: 56px;
//   font-style: normal;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 28px;
//   letter-spacing: -0.01em;
//   color: #03153D;
// `

const availableSelectionYearsRange = [{
    start: 2017,
    end: new Date().getFullYear()
}];

/*for(let i = 2017; i <= new Date().getFullYear(); i++){
    availableSelectionYearsRange.push({start: i, end: i + 1});
}*/

const News = () => {
    const [selectedPageIndex, setSelectedPageIndex] = useState(1);
    const [availablePageIndexes, setAvailablePageIndexes] = useState([1]);
    const [pagesCount, setPagesCount] = useState(1);
    const [startSelectedYearRange, setStartSelectedYearRange] = useState(2023);
    const [endSelectedYearRange, setEndSelectedYearRange] = useState(2022)
    const [pageNews, setPageNews] = useState([]);
    const [selectedYearNews, setSelectedYearNews] = useState([]);
    const history = useHistory();
    const { isDesktop } = useWindowSize();
    const { newsContent, setNewsContent } = useNewsContent();
    const { t } = useTranslation();

    const newsPageCount = isDesktop ? 15 : 6
    const maxAvailablePagesCount = isDesktop ? 5 : 3;

    useEffect(() => {
        if (!newsContent) {
            dataService
                .getAllNews(selectedPageIndex)
                .then((news) => {
                    setNewsContent(news.data);
                    setPagesCount(news.meta.last_page)
                    const filteredByCurrentYearNews = news.data.filter((newsData) => {
                        const publishDate = new Date(newsData.publish_date);
                        return (
                            startSelectedYearRange >= publishDate.getFullYear()
                            && endSelectedYearRange <= publishDate.getFullYear()
                        );
                    })
                    setSelectedYearNews(filteredByCurrentYearNews);
                    setPageNews(filteredByCurrentYearNews.slice(0, Math.min(newsPageCount, filteredByCurrentYearNews.length)));
                });
        }
    }, [ ]);

    useEffect(()=>{
        dataService
            .getAllNews(selectedPageIndex)
            .then((news) => {
                setNewsContent(news.data);
                setPagesCount(news.meta.last_page)
                const filteredByCurrentYearNews = news.data.filter((newsData) => {
                    const publishDate = new Date(newsData.publish_date);
                    return (
                        startSelectedYearRange >= publishDate.getFullYear()
                        && endSelectedYearRange <= publishDate.getFullYear()
                    );
                })
                setSelectedYearNews(filteredByCurrentYearNews);
                setPageNews(news.data);
            });
    },[selectedPageIndex])

    useEffect(() => {
        // setPagesCount(Math.floor(selectedYearNews.length / newsPageCount) + (selectedYearNews.length % newsPageCount === 0 ? 0 : 1) );
    }, [selectedYearNews, newsPageCount]);

    useEffect(() => {
        const newAvailablePages = [];
        let startPageIndex = 1;
        let availablePagesCount = Math.min(pagesCount - startPageIndex, maxAvailablePagesCount);
        while(availablePagesCount) {
            availablePagesCount--;
            newAvailablePages.push(startPageIndex);
            startPageIndex++;
        }
        setAvailablePageIndexes(newAvailablePages);
    }, [pagesCount, newsPageCount])


    const onChangeSelectedPage = (pageIndex) => {
        setSelectedPageIndex(pageIndex);
        const pageStartIndex = pageIndex * newsPageCount;
        let newsAvailableCount = 0;
        for (let i=0;i<newsPageCount;i++){
            if (pageIndex * newsPageCount + i < selectedYearNews.length) {
                newsAvailableCount++;
            } else {
                break;
            }
        }
        setPageNews(selectedYearNews.slice(pageStartIndex, pageStartIndex + newsAvailableCount));
    }

    const onGoNextPages = () => {
        const newAvailablePages = [];
        let startPageIndex = availablePageIndexes[availablePageIndexes.length - 1] + 1;
        let availablePagesCount = Math.min((pagesCount - startPageIndex) + 1, maxAvailablePagesCount);
        while(availablePagesCount) {
            availablePagesCount--;
            newAvailablePages.push(startPageIndex);
            startPageIndex++;
        }
        setAvailablePageIndexes(newAvailablePages);
    }

    const onGoPreviousPages = () => {
        const newAvailablePages = [];
        let lastPageIndex = availablePageIndexes[0] - 1;
        let availablePagesCount = maxAvailablePagesCount;
        while(availablePagesCount) {
            availablePagesCount--;
            newAvailablePages.push(lastPageIndex);
            lastPageIndex--;
        }
        setAvailablePageIndexes(newAvailablePages.reverse());
    }


    return(
        <Layout>
            <div>
                <PathHeader paths={[t('Main'), t('News')]}/>
                <ContentWrapper>
                    <ContentHeader title={t('News')}/>
                    <SelectYearsWrapper>
                        {
                            availableSelectionYearsRange.map((availableYearRange) => {
                                const yearRangeName =
                                    availableYearRange.start === availableYearRange.end
                                        ? availableYearRange.start
                                        : `${availableYearRange.start}-${availableYearRange.end}`;
                                return (
                                    <SelectYearWrapper>
                                        {
                                            (
                                                startSelectedYearRange === availableYearRange.start
                                                && endSelectedYearRange === availableYearRange.end
                                            ) ? (
                                                <>
                                                    <SelectYearTitle>{yearRangeName}</SelectYearTitle>
                                                    <SelectYearIndicator style={{ backgroundColor: "#0068FF" }}/>
                                                </>
                                            ): (
                                                <>
                                                    {yearRangeName}
                                                </>
                                            )
                                        }
                                    </SelectYearWrapper>
                                )
                            })
                        }
                    </SelectYearsWrapper>
                    <NewsGrid>
                        {
                            pageNews.map((news, index) => {
                                const date = new Date(news.publish_date);
                                const monthName = dateConvertedLib.monthIndexToName(date.getMonth());
                                const dayNumber = date.getDate();
                                const year = date.getFullYear();
                                return (
                                    <NewsBlock
                                        onClick={() => {
                                            history.push(`/news/${news.slug}`)
                                        }}
                                    >
                                        {news.image?<NewsBlockImage src={news.image}/>:Icons.nullImage}
                                        <NewsBlockDate>{`${dayNumber} ${monthName} ${year}`}</NewsBlockDate>
                                        <NewsBlockTitle>{ news.title }</NewsBlockTitle>
                                    </NewsBlock>
                                )
                            })
                        }
                    </NewsGrid>
                    <PageSelectionWrapper>
                        <PageSelectionButton
                            onClick={() => {
                                if (availablePageIndexes[0] !== 1) {
                                    onGoPreviousPages();
                                }
                            }}
                            style={{
                                cursor: availablePageIndexes[0] === 1 ? "not-allowed" : "pointer",
                            }}
                        >
                            ←
                        </PageSelectionButton>
                        {
                            availablePageIndexes.map((index) => {
                                return (
                                    <PageSelectionButton
                                        onClick={() => onChangeSelectedPage(index)}
                                        style={{
                                            backgroundColor: selectedPageIndex === index ? "#0029D3" : "#E6E8EC",
                                            borderWidth: selectedPageIndex === index ? "0px" : "2px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: selectedPageIndex === index ? "#FCFCFD" : "#171717"
                                            }}
                                        >{index}</div>
                                    </PageSelectionButton>
                                )
                            })
                        }
                        <PageSelectionButton
                            onClick={() => {
                                if (availablePageIndexes[availablePageIndexes.length - 1] + 1 <= pagesCount) {
                                    onGoNextPages();
                                }
                            }}
                            style={{
                                cursor: availablePageIndexes[availablePageIndexes.length - 1] >= pagesCount ? "not-allowed" : "pointer",
                            }}
                        >
                            →
                        </PageSelectionButton>
                    </PageSelectionWrapper>
                </ContentWrapper>
            </div>
        </Layout>)
}
export default News;
