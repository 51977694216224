import {useContext} from 'react';
import {ModuleContext} from "../context/ModuleProviders";


export default function useModule() {
    const { isModuleOpen,
        setModule} = useContext(ModuleContext);

    return {
        isModuleOpen,
        setModule
    }
}
