import axios from 'axios';

const axiosApiInstance = axios.create();
const MAIN_URL = 'https://admin.kkb.kg/api';

class SendDataService {
    async sendFeedback(data) {
        const headers = {
            headers: {
                'Access-Control-Allow-Origin':'*',
                'Content-Type': 'application/json',
                'Content-Language': localStorage.getItem('currentLang') || 'ru',
            }
        };

        const url = MAIN_URL + '/grades/store';
        return axiosApiInstance.post(url, {...data},headers).then((response) => response.data);
    }
}

export const sendDataService = new SendDataService();
