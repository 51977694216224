import React, { useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Icons } from "../../Icons/Icons";
import { ProductSkeleton } from "../Cover/Cover";
import useLoading from "../../hooks/useLoading";
import useWindowSize from "../../hooks/useWindowSize";
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  padding-top: 128px;
  @media (max-width: 768px) {
    padding-top: unset;
  }
`;

const Content = styled.div`
  width: 1120px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: unset;
    margin: unset;
  }
`;

const Title = styled.div`
  font-family: Nexa;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #03153d;
  padding-bottom: 64px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
    padding: 32px;
  }
`;

const Cards = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const CardsMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 8px;
    margin: 32px;

    .item1 {
      grid-column: 1 / 2;
      grid-row: 1;
    }
    .item3 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      display: flex;
    }

    .text3 {
      white-space: nowrap;
    }
  }
`;

const Card = styled.div`
  max-width: 200px;
  background: #ffffff;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  margin-right: 32px;
  border-radius: 16px;
  padding: 16px;
  margin: 16px;
  margin-bottom: 128px;
  width: 200px;
  transition: all 0.1s ease-in-out;
  position: relative;
  height: 200px;
  .arrow {
    display: none;
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  &:hover {
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    height: 220px;
    margin-bottom: 108px;
    transition: height 0.1s ease-in;
    .arrow {
      display: block;
    }
  }
  @media (max-width: 768px) {
    margin: 0;
    width: unset !important;
    max-width: unset !important;
    flex-direction: row-reverse;
    &:hover {
      transition: unset;
      height: unset;
      margin-bottom: unset;
    }
  }
`;

const CardTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #03153d;
  padding-bottom: 4px;
`;

const CardSubtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #777e91;
  padding-bottom: 16px;
`;

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Img = styled.img`
  padding-bottom: 8px;
  object-fit: contain;
  height: 90px;

  @media (max-width: 768px) {
    width: 100%;
    height: unset;
  }
`;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const ContentSkeletLoader = () => {
  const prod = ["", "", "", "", ""];
  return (
    <Cards style={{ display: "flex", justifyContent: "space-between" }}>
      {prod.map((e, i) => {
        return (
          <Card key={i + Math.random()}>
            <ImgWrapper>
              <ProductSkeleton height="100px" />
            </ImgWrapper>
            <CardTitle>
              <ProductSkeleton />
            </CardTitle>
            <CardSubtitle>
              <ProductSkeleton />
            </CardSubtitle>
          </Card>
        );
      })}
    </Cards>
  );
};

const MobileContentSkeletLoader = () => {
  const prod = ["", "", "", "", ""];
  return (
    <CardsMobile>
      {prod.map((e, i) => {
        return (
          <Card key={i + Math.random()} className={"item" + (i + 1)}>
            <ImgWrapper>
              <ProductSkeleton height="100px" />
            </ImgWrapper>
            <div>
              <CardTitle className={"text" + (i + 1)}>
                <ProductSkeleton />
              </CardTitle>
              <CardSubtitle>
                <ProductSkeleton />
              </CardSubtitle>
            </div>
          </Card>
        );
      })}
    </CardsMobile>
  );
};

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    ></div>
  );
}

const PopularProducts = (props) => {
  const { isLoading } = useLoading();
  const { isDesktop } = useWindowSize();
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Wrapper data-aos="fade-up" data-aos-duration="500">
      <Content>
        <Title>{t('Popular products')}</Title>
        {isDesktop && (
          <div>
            {isLoading && props?.products ? (
              <Cards>
                <Slider {...settings}>
                  {props?.products?.map((e, i) => {
                    return (
                      <Card key={i + Math.random()}>
                        <ImgWrapper>
                          <Img src={e.image} />
                        </ImgWrapper>
                        <CardTitle>{e.title}</CardTitle>
                        <CardSubtitle>{e.intro}</CardSubtitle>
                        <div
                          className="arrow"
                          onClick={() => window.open(e.link, "_self")}
                        >
                          {Icons.arrow}
                        </div>
                      </Card>
                    );
                  })}
                </Slider>
              </Cards>
            ) : (
              <ContentSkeletLoader />
            )}
          </div>
        )}

        {!isDesktop && isLoading && props?.products ? (
          <CardsMobile>
            {props?.products?.map((e, i) => {
              return (
                <Card
                  key={i + Math.random()}
                  className={"item" + e.id}
                  onClick={() => window.open(e.link, "_self")}
                >
                  <ImgWrapper>
                    <Img src={e.image} />
                  </ImgWrapper>
                  <div>
                    <CardTitle className={"text" + e.id}>{e.title}</CardTitle>
                    <CardSubtitle>{e.intro}</CardSubtitle>
                  </div>
                </Card>
              );
            })}
          </CardsMobile>
        ) : (
          <MobileContentSkeletLoader />
        )}
      </Content>
    </Wrapper>
  );
};

export default PopularProducts;
