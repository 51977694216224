import React, { useState } from "react";
import styled from "styled-components";
import { Wrapper, Content, Button } from "../UI/CommonClasses";
import useLoading from "../../hooks/useLoading";
import { keyframes } from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import Slider from "react-slick";

const InfoPaginator = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);

  border-radius: 8px;
  padding: 24px 40px;
  margin-top: -88px;
  display: flex;
  flex-direction: row;
  width: 1120px;
  justify-content: space-between;

  .slick-current {
    .lineNav {
      border-color: #ffc200;
      color: #141416;
    }

    .titleNav {
      color: #03153d;
    }
  }
`;
const PaginatorItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px !important;
  cursor: pointer;
`;

const ItemTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #777e91;
`;

const ItemLine = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;
  border-top: 1px solid #8298ab;
  width: 100%;
`;

const ItemText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #777e91;
`;

const RightContent = styled.div``;

const LeftContent = styled.div`
  margin: auto 0;
  height: 240px;

  @media (max-width: 768px) {
    height: 350px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
const Title = styled.div`
  font-family: "Nexa";
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 56px;

  letter-spacing: -0.02em;
  color: #03153d;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
  }
`;
const SubTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #353945;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: auto;
  }
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const ProductSkeleton = styled.div`
  display: inline-block;
  height: ${(props) => props.height || "14px"};
  width: ${(props) => props.width || "80%"};
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: ${(props) => props.marginTop || "0"};
`;

const Cover = (props) => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  let info = props.info ? [...props.info] : [];

  const { isDesktop } = useWindowSize();
  const { isLoading, setLoading } = useLoading();

  const ContentSkeletLoader = () => {
    return (
      <Content direction={"row"} style={{ margin: "auto 0" }}>
        <LeftContent>
          <Title>
            <ProductSkeleton height="40px" />
          </Title>
          <SubTitle>
            <ProductSkeleton width="250px" height="50px" />
          </SubTitle>
          <Action>
            <ProductSkeleton width="200px" height="50px" />
          </Action>
        </LeftContent>
        <RightContent></RightContent>
      </Content>
    );
  };

  const ContentPaginatorSkeletLoader = () => {
    const elements = ["", "", "", ""];
    return (
      <InfoPaginator>
        <div
          style={{
            maxWidth: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {elements.map((el, index) => (
            <PaginatorItem
              style={{ maxWidth: "100%", width: "100%" }}
              key={index + Math.random()}
            >
              <ItemTitle className={"titleNav"}>
                <ProductSkeleton width="250px" height="50px" />
              </ItemTitle>
              <ItemLine className={"lineNav"} />
              <ItemText>
                <ProductSkeleton height="50px" />
              </ItemText>
            </PaginatorItem>
          ))}
        </div>
      </InfoPaginator>
    );
  };

  return (
    <div>
      {isLoading && props?.info ? (
        <Slider
          asNavFor={nav2}
          ref={(slider1) => setNav1(slider1)}
          style={{ maxWidth: "100%" }}
          varvariableWidth={true}
          infinite={true}
          centerMode={!isDesktop}
        >
          {info.map((el, index) => (
            <Wrapper
              back={isDesktop ? el?.image : ""}
              key={index + Math.random()}
              style={{
                minHeight: isDesktop ? "640px" : "unset",
                overflow: "hidden",
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "16px 0",
                width: isDesktop ? "unset" : "290px",
                borderRadius: isDesktop ? "unset" : "16px !important",
              }}
            >
              <Content
                direction={isDesktop ? "row" : "column-reverse"}
                className={!isDesktop ? "mobile_cover" : "cover_pg"}
                style={{
                  margin: isDesktop ? "0 auto" : "0 8px",
                  maxWidth: "1120px",
                  padding: isDesktop ? "200px 0" : "30px 0 24px 0",
                  background: isDesktop ? "null" : `url(${el?.mobile_image})`,
                  width: isDesktop ? "unset" : "95%",
                  backgroundSize: "100% 100%",
                  backgroundPosition: "center",
                }}
                key={index + Math.random()}
              >
                <LeftContent>
                  <Title style={{ color: el.color }}>{el?.title}</Title>
                  <SubTitle style={{ color: el.color }}>{el?.intro}</SubTitle>
                  <Action>
                    <Button
                      arrow={false}
                      onClick={() => {
                        if (el.button_link)
                          return window.open(el?.button_link, "_self");
                      }}
                    >
                      {el?.button_title}
                    </Button>
                  </Action>
                </LeftContent>
              </Content>
            </Wrapper>
          ))}
        </Slider>
      ) : (
        <Wrapper>
          <ContentSkeletLoader />
        </Wrapper>
      )}

      {isDesktop && isLoading && props?.info ? (
        <Wrapper>
          <InfoPaginator>
            <div style={{ maxWidth: "100%", width: "100%" }}>
              <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={info.length}
                swipeToSlide={true}
                focusOnSelect={true}
                className={"activeNav"}
              >
                {info.map((el, index) => (
                  <PaginatorItem
                    style={{ maxWidth: "100%", width: "100%" }}
                    key={index + Math.random()}
                  >
                    <ItemTitle className={"titleNav"}>{el?.title}</ItemTitle>
                    <ItemLine className={"lineNav"} />
                    <ItemText>{el?.description}</ItemText>
                  </PaginatorItem>
                ))}
              </Slider>
            </div>
          </InfoPaginator>
        </Wrapper>
      ) : (
        <div>
          {isDesktop && (
            <Wrapper>
              <ContentPaginatorSkeletLoader />
            </Wrapper>
          )}
        </div>
      )}
    </div>
  );
};

export default Cover;
