import React from "react";
import styled from "styled-components";
import {Icons} from "../../Icons/Icons";
import {useHistory} from "react-router-dom";

const Header = styled.div`
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.01em;
  color: #141416;
  align-items: center;
  display: flex;
  @media (max-width: 768px) {
    font-weight: 900;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
  }
`;

export const ContentHeader = (props) => {
    const history = useHistory();
    return (
        <Header>
            <div style={{paddingRight:'24px', display:'flex',alignItems:'center',cursor:'pointer'}} onClick={()=> {
                if(props.backLink) return history.push('/'+props.backLink);
                else return history.push('/');
            }}> {Icons.ArrowLeft}</div>
            {props.title}
        </Header>
    )
}



