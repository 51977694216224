import { useState, useEffect } from "react";

 const useWindowSize=()=> {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        isDesktop: window.innerWidth >= 768,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                isDesktop: window.innerWidth >= 768,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

export default useWindowSize;