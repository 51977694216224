import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { ProductSkeleton } from "../Cover/Cover";
import { Button } from "../UI/CommonClasses";
import useLoading from "../../hooks/useLoading";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const Content = styled.div`
  width: 1120px;
  justify-content: space-between;
  display: flex;
  color: #fcfcfd;
  flex-direction: row;
  background: #f7f9fe;
  border-radius: 24px;
  height: 512px;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin: 32px 32px 32px 32px;
    padding: 32px 24px 0 24px;
    height: unset;
  }
`;

const LeftContent = styled.div`
  padding-left: 64px;
  flex-grow: 1;
  flex-basis: 0;

  @media (max-width: 768px) {
    padding-left: unset;
    margin-bottom: 20px;
  }
`;

const Subtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #353945;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Title = styled.div`
  font-family: Nexa;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;

  letter-spacing: -0.01em;
  color: #03153d;
  padding-bottom: 16px;
  width: 478px;

  @media (max-width: 768px) {
    font-size: 24px;
    width: unset;
    line-height: 120%;
  }
`;
const RightContent = styled.div`
  color: black;
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  height: 100%;
`;

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ContentSkeletLoader = () => {
  return (
    <Content>
      <LeftContent>
        <Title>
          <ProductSkeleton height="30px" />
        </Title>
        <Subtitle>
          <ProductSkeleton />
        </Subtitle>
        <ProductSkeleton height="50px" />
      </LeftContent>
      <RightContent></RightContent>
    </Content>
  );
};

const MobileBankBaner = ({ mobile }) => {
  const { isLoading } = useLoading();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Wrapper data-aos="fade-up" data-aos-duration="500">
      {isLoading && mobile ? (
        <Content>
          <LeftContent>
            <Title>{mobile?.title}</Title>
            <Subtitle>{mobile?.description}</Subtitle>
            <Button
              onClick={() => {
                if (mobile.button_link)
                  return window.open(mobile?.button_link, "_self");
              }}
            >
              {mobile?.button_title}
            </Button>
          </LeftContent>
          <RightContent>
            <Img src={mobile?.image} />
          </RightContent>
        </Content>
      ) : (
        <ContentSkeletLoader />
      )}
    </Wrapper>
  );
};

export default MobileBankBaner;
